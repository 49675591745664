import React from "react";
import "./AwardsCategory.css";
import ellipse from "../../../../assets/img/Ellipse.png";

// product of the year category images
import prod1 from "../../../../assets/img/product_year/webp/1.webp";
import prod2 from "../../../../assets/img/product_year/webp/2.webp";
import prod3 from "../../../../assets/img/product_year/webp/3.webp";
import prod4 from "../../../../assets/img/product_year/webp/4.webp";
import prod5 from "../../../../assets/img/product_year/webp/5.webp";

// product launch of the year category images
import launch1 from "../../../../assets/img/product_launch/webp/1.webp";
import launch2 from "../../../../assets/img/product_launch/webp/2.webp";
import launch3 from "../../../../assets/img/product_launch/webp/3.webp";
import launch4 from "../../../../assets/img/product_launch/webp/4.webp";
import launch5 from "../../../../assets/img/product_launch/webp/5.webp";

// Brand of the year category images
import brand1 from "../../../../assets/img/brand_year/webp/1.webp";
import brand2 from "../../../../assets/img/brand_year/webp/2.webp";
import brand3 from "../../../../assets/img/brand_year/webp/3.webp";
import brand4 from "../../../../assets/img/brand_year/webp/4.webp";
import brand5 from "../../../../assets/img/brand_year/webp/5.webp";

import brand_logo1 from "../../../../assets/img/webp/seal1.webp";
import brand_logo2 from "../../../../assets/img/webp/seal2.webp";
import brand_logo3 from "../../../../assets/img/webp/seal3.webp";
import brand_logo4 from "../../../../assets/img/webp/seal4.webp";
import brand_logo5 from "../../../../assets/img/webp/seal5.webp";
import brand_logo6 from "../../../../assets/img/webp/seal6.webp";
import brand_logo7 from "../../../../assets/img/webp/seal7.webp";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// data for images
const data = [
  { image_url: brand1 },
  { image_url: brand2 },
  { image_url: brand3 },
  { image_url: brand4 },
  { image_url: brand5 },

  { image_url: launch1 },
  { image_url: launch2 },
  { image_url: launch3 },
  { image_url: launch4 },
  { image_url: launch5 },

  { image_url: prod1 },
  { image_url: prod2 },
  { image_url: prod3 },
  { image_url: prod4 },
  { image_url: prod5 },
];
// dont touch these constants
const totalProds = data.length;
const tiltConstant = 360 / totalProds;

export default function AwardCategory() {
  const [category, setCategory] = React.useState(0);
  // const handleCategory = () => {
  //   if (category === 2) {
  //     setCategory(0);
  //   } else {
  //     setCategory(category + 1);
  //   }
  // };

  // category button functionality
  const handleNext = () => {
    console.log("next");
    if (category === 5) {
      catRef.current = 0;
      setCategory(0);
    } else {
      catRef.current = category + 1;
      setCategory(category + 1);
    }
  };
  const handlePrev = () => {
    console.log("prev");
    if (category === 0) {
      catRef.current = 5;
      setCategory(5);
    } else {
      catRef.current = category - 1;
      setCategory(category - 1);
    }
  };

  const catRef = React.useRef(0);
  const handleRef = () => {
    if (catRef.current === 5) {
      catRef.current = 0;
    } else {
      catRef.current += 1;
    }
    setCategory(catRef.current);
  };
  React.useEffect(() => {
    const intervalChangeSlide = setInterval(() => {
      handleRef();
    }, 6000);
    return () => {
      clearInterval(intervalChangeSlide);
    };
  }, [category]);

  const Product = ({ item, index }) => {
    const tiltMultiplier = index + 1;
    return (
      <img
        alt="product"
        src={item.image_url}
        className={
          "productImage " +
          (category === 0
            ? "rotate0"
            : category === 1
            ? "rotate60"
            : "rotate120")
        }
        style={{ "--tilt": `${tiltConstant * tiltMultiplier}deg` }}
      />
    );
  };
  return (
    <section className="categorySec bg-brown h_100vh" id="category">
      <div className="innerDiv">
        <div className="section_title">
          <h2 className="text-white">AWARDS CATEGORIES</h2>
        </div>
        <div className="awardsCategoryMainnnn">
          <h3
            className={category === 0 ? "onScreenHeading" : "offScreenHeading"}
          >
            Brand of the year
          </h3>
          <h3
            className={category === 1 ? "onScreenHeading" : "offScreenHeading"}
          >
            Product of the year
          </h3>
          <h3
            className={category === 2 ? "onScreenHeading" : "offScreenHeading"}
          >
            Product launch of the year
          </h3>
          <h3
            className={category === 3 ? "onScreenHeading" : "offScreenHeading"}
          >
            Digital initiative of the year
          </h3>
          <h3
            className={category === 4 ? "onScreenHeading" : "offScreenHeading"}
          >
            D2C product of the year
          </h3>
          <h3
            className={category === 5 ? "onScreenHeading" : "offScreenHeading"}
          >
            D2C brand of the year
          </h3>

          {category === 0 && (
            <p className="frontText">
              The Brand of the Year is a distinctive and premier recognition for
              a brand recognized as a champion in its industry category based on
              current year market standing and consumer preference.
            </p>
          )}
          {category === 1 && (
            <p className="frontText">
              The Product of the Year Award celebrates the best products in the
              market in different categories of FMCG products and rewards
              manufacturers for quality, strategy, marketing and other
              parameters.
            </p>
          )}
          {category === 2 && (
            <p className="frontText">
              The Product Launch of the Year Award celebrates the efforts of
              FMCG companies who continuously develop new products, brand
              extensions and packaging updates to meet the new industry
              standards and demands of the consumers.
            </p>
          )}

          {category === 3 && (
            <p className="frontText">
              The Digital Initiative of the Year award recognizes outstanding
              digital transformation initiatives within the Fast-Moving Consumer
              Goods (FMCG) industry.
            </p>
          )}
          {category === 4 && (
            <p className="frontText">
              The D2C (Direct-to-Consumer) Product of the Year Award is an
              honour given to a product that has effectively utilised its
              direct-to-consumer sales channel to reach and engage with its
              target audience, offering a unique and personalised shopping
              experience.
            </p>
          )}

          {category === 5 && (
            <p className="frontText">
              The D2C (Direct-to-Consumer) Brand of the Year Award is an honour
              given to a brand that has successfully established and grown its
              direct-to-consumer business, offering consumers a seamless and
              convenient shopping experience through its online channels, such
              as its e-commerce platform or mobile app, disrupting traditional
              retail models.
            </p>
          )}

          <div className="clippedCircle">
            <img
              alt="ellipse dashed line"
              src={ellipse}
              className={
                "ellipseDashed " +
                (category === 0 ? "rotate30" : category === 1 ? "rotate60" : "")
              }
            />
            <div
              className={
                "productContainer " +
                (category === 0 ? "cat1Products" : "cat2Products")
              }
            >
              {data.map((item, index) => (
                <Product item={item} index={index} key={index} />
              ))}
              <img
                alt="logo"
                src={brand_logo3}
                className={
                  "brandLogo " + (category === 0 ? "comeUp" : "goDown")
                }
              />
              <img
                alt="logo"
                src={brand_logo1}
                className={
                  "brandLogo " + (category === 1 ? "comeUp" : "goDown")
                }
              />
              <img
                alt="logo"
                src={brand_logo2}
                className={
                  "brandLogo " + (category === 2 ? "comeUp" : "goDown")
                }
              />

              <img
                alt="logo"
                src={brand_logo4}
                className={
                  "brandLogo " + (category === 3 ? "comeUp" : "goDown")
                }
              />
              <img
                alt="logo"
                src={brand_logo5}
                className={
                  "brandLogo " + (category === 4 ? "comeUp" : "goDown")
                }
              />
              <img
                alt="logo"
                src={brand_logo6}
                className={
                  "brandLogo " + (category === 5 ? "comeUp" : "goDown")
                }
              />
            </div>
            <div className="category_buttons_prev_nxt">
              <button className="prv_btn" onClick={handlePrev}>
                <FaArrowLeft />
              </button>
              <button className="nxt_btn" onClick={handleNext}>
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
