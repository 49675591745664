import React from "react";
// import "./policy.css";
import InfoCard from "../../../components/InfoCard/InfoCard";

const data = [
  {
    title: "Memento and certificate of excellence -",
    description: [
      {
        data: "More than receiving trophies and recognition from judges and industry peers, the award serves as a seal of excellence for your company's continuous pursuit for growth through outstanding innovations and transformation of strategies.",
        fontSize: "16px",
        type: "text",
      },
    ],
  },
  {
    title: "Attain valuable exposure -",
    description: [
      {
        data: "A press release focusing on your award and highlighting your achievement as a winner will be published in the most popular online publications across the country.",
        fontSize: "16px",
        type: "text",
      },
    ],
  },
  {
    title: "Boost your company’s prestige and credibility -",
    description: [
      {
        data: "The most sought after entitlement, the use of Channelier FMCG awards logo is the best way to lock in your title as an award-winning company. Winners may use the official awards logo and digital seal as a seal of excellence in their marketing and promotional materials such as product packaging, print and online ads, email signatures, social media posts, and websites.",
        fontSize: "16px",
        type: "text",
      },
    ],
  },
  {
    title: "Attract new business opportunities -",
    description: [
      {
        data: "Be a part of the coffee table book to be shared with the top FMCG retailers in India.",
        fontSize: "16px",
        type: "text",
      },
    ],
  },
];

const WhatIsForYou = () => {
  return (
    <section className="policy_container bg-brown">
      <div className="section_title">
        <h2 className="text-white">What’s in it for Winners</h2>
      </div>
      <div className="container-fluid">
        <div className="whatsForYour_2By2_container">
          {data.map((item, index) => {
            return <InfoCard data={item} key={index} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default WhatIsForYou;
