import { React , useState, useEffect } from 'react';
 import { Modal , Button, Form } from 'react-bootstrap';
 import user from '../../../../store/action/user';
import { useSelector,useDispatch } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import category from '../../../../assets/img/2023/modal_categry.png';
import register from "../../../../assets/img/rightarrow.png";


function HomeModal(){
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [mobile,setMobile]=useState();
    const userDetail=useSelector((state)=>state.user)
    const dispatch=useDispatch()
    const navigate=useNavigate()
    //  console.log(userDetail);
    useEffect(() => {
      setTimeout(() => {
        if (!userDetail.isLoggedOut && !userDetail.isLoggedIn) {
          handleShow()
         }
      }, 4000);
     
    }, [userDetail]);

    const saveLead=(e)=>{
      e.preventDefault()
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const campaignValue = params.get('v');
      const body={
        value:mobile,
        campaign:campaignValue,
        source:"popup"
      }
      if (!mobile) {
        toast.warn("Mobile Number can't be blank !!", {
          autoClose: 1000
        });
        return
      }
      if (mobile.length!==10) {
        toast.warn("Please enter a valid mobile number  !!", {
          autoClose: 1000
        });
        return
      }
      dispatch(user.leadCapture(body))
      toast.success("Submitted successfully !", {
        autoClose: 1000
      });
      handleClose()
      navigate(`/login?m=${mobile}`);
    }
    

    return(
        <>
          <ToastContainer />
        <Modal className='homeModal' show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <p>Submit your free nominations now!</p> */}
          </Modal.Header>

          <Modal.Body className='nomination_modal'>
            
            <div className='nomination_row'>
              <div className='nomination_modal_catgry'>
                <img src={category} alt='category'/>
                <h1>200+</h1>
                <h3>brands have already nominated</h3>
              </div>


              <div className='nomination_modal_content'>
                    <p className='text-white'>
                      To know more about Channelier FMCG Awards, <br/>
                     drop your number here.
                    </p>
                    <Form>
                      <Form.Group className="form_row mb-0" controlId="formBasicEmail">
                        <Form.Control  type="text" minLength={10} maxLength={10} placeholder="Enter Mobile Number" onChange={(e)=>setMobile(e.target.value)}  />
                        <Button type='submit' className='custom_btn' variant="primary" onClick={saveLead}>
                        <img src={register} alt="register" />
                        </Button>
                      </Form.Group>



                    </Form>

                    {/* <h3>Nomination ends on </h3>

                    <div className='nomination_date'> <span>1</span>  <span>5</span>  <span>Oct’23</span> </div>
                    <div className="registerNow">
                      <Link to="/login" target="_blank">
                        <button type="button" className="custom_btn">
                          Register Now
                          <div className="arrow_register">
                            <img src={register} alt="register" />
                          </div>
                        </button>
                      </Link>
                    </div> */}
              </div>

            </div>
                  
          </Modal.Body>
         
        </Modal>
      </>
    )
}

export default  HomeModal;