// Importing the requirements
import http from "../../services/HttpClient";
import storage from "../../services/localStorage";
import {
  UPDATE_USER,
  SET_ERROR,
  GET_USER,
  SEND_OTP,
  USER_LOGIN,
  VERIFY_OTP,
  USER_LOGOUT,
  RESET_OTP,
  GET_JURY,
  GET_COMAPNY_NOMINATION,
  GET_COMAPNY_DETAIL,
  SAVE_VOTE,
  RESET_VERIFY_OTP,
  RESET_VOTE,
  CREATE_INTERESTED_USER,
  GET_USER_INTEREST,
} from "../constant/types";

// Function to Send the OTP
const sendOtp = (body, cb) => async (dispatch) => {
  try {
    const { data } = await http.post("/api/send-otp", body);
    console.log(data);
    dispatch({
      type: SEND_OTP,
      payload: { list: data },
    });
    cb();
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Sending the OTP !", error);
  }
};

// Function to Verify the OTP
const verifyOtp = (body) => async (dispatch) => {
  try {
    let { data } = await http.post("/api/verify-otp", body);
    console.log("Data", data);

    if (data.status === 1) data = 1;
    else data = -1;

    dispatch({
      type: VERIFY_OTP,
      payload: { otpVerification: data },
    });

    // dispatch({
    //   type: VERIFY_OTP,
    //   payload: { otpVerification: data },
    // });
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error occurred in Verifying the OTP !", error);
  }
};

// Function to Verify the OTP
const resetOtp = (body) => async (dispatch) => {
  dispatch({
    type: RESET_OTP,
    payload: { otpVerification: 2 },
  });
};

const resetVote = (body) => async (dispatch) => {
  dispatch({
    type: RESET_VOTE,
    payload: { list: {} },
  });
};

// Function to Create the New User Profile
const createUser =
  (body, cb = () => {}) =>
  async (dispatch) => {
    try {
      const { data } = await http.post("/api/create-user", body);
      await storage.setUser({
        user: { ...data, role: "user" },
        token: storage.getToken(),
      });
      await dispatch({
        type: UPDATE_USER,
        payload: {
          list: {
            user: { ...data, role: "user" },
            token: storage.getToken(),
          },
        },
      });
      cb(body);
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: { message: `storage error: ${error}` },
      });
      console.log("Some Error Occured in Creating a New User Profile !", error);
    }
  };

// Function to Login the User
const loginUser = (body) => async (dispatch) => {
  try {
    const { data } = await http.post("/api/user-login", body);
    console.log("Data", data);
    storage.setUser(data);
    localStorage.setItem("token", data.token);
    dispatch({
      type: USER_LOGIN,
      payload: { list: data },
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Logging the User !", error);
  }
};

// Function to Logout the User
const logoutUser = () => (dispatch) => {
  try {
    const data = storage.removeUser();
    localStorage.clear();
    dispatch({
      type: USER_LOGOUT,
      payload: { list: data },
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Logging out the User !", error);
  }
};

// Function to Get the User
const getUser = () => (dispatch) => {
  try {
    const data = storage.getUser();
    if (data && data.token) {
      dispatch({
        type: USER_LOGIN,
        payload: { list: data },
      });
    }
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Getting the User Profile !", error);
  }
};

// Function to Get the User by ID
const getUserById = (body) => async (dispatch) => {
  try {
    const { data } = await http.post("/api/get-user-by-id", body);
    dispatch({
      type: GET_USER,
      payload: { list: data },
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Getting the User ID !", error);
  }
};

// Function to Update the User Profile
const updateUser =
  (body, cb = () => {}) =>
  async (dispatch) => {
    try {
      const { data } = await http.post("/api/update-user-profile", body);
      dispatch({
        type: UPDATE_USER,
        payload: { list: data },
      });
      cb();
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: { message: `storage error: ${error}` },
      });
      console.log("Some Error Occured in Updating the User Profile !", error);
    }
  };

// Function to Get the User by ID
const saveUserQuery = (body) => async (dispatch) => {
  try {
    const { data } = await http.post("/api/user-query", body);
    dispatch({
      type: GET_JURY,
      payload: { list: data?.data },
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Getting the User ID !", error);
  }
};

// Function to Get the User by ID
const leadCapture = (body) => async (dispatch) => {
  try {
    const { data } = await http.post("/api/lead-capture", body);
    dispatch({
      type: GET_JURY,
      payload: { list: data?.data },
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Getting the User ID !", error);
  }
};

// Function to Get the User by ID
const websiteClickCapture = (body) => async (dispatch) => {
  try {
    const { data } = await http.post("/api/website-clicks", body);
    dispatch({
      type: GET_JURY,
      payload: { list: data?.data },
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Getting the User ID !", error);
  }
};

const getCompanyNomination = (body) => async (dispatch) => {
  try {
    const { data } = await http.post("/api/get-vote-nominations", body);
    dispatch({
      type: GET_COMAPNY_NOMINATION,
      payload: { list: data?.response },
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Getting the User ID !", error);
  }
};

const getCompanyDetail = (body) => async (dispatch) => {
  try {
    const { data } = await http.post("/api/get-user-detail", body);
    dispatch({
      type: GET_COMAPNY_DETAIL,
      payload: { list: data?.user },
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Getting the User ID !", error);
  }
};

const saveUserVote = (body) => async (dispatch) => {
  try {
    const { data } = await http.post("/api/saveVote", body);
    dispatch({
      type: SAVE_VOTE,
      payload: { list: data },
    });
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `storage error: ${error}` },
    });
    console.log("Some Error Occured in Getting the User ID !", error);
  }
};
//2023
const saveInterestedUser =
  (body, cb = () => {}) =>
  async (dispatch) => {
    try {
      const { data } = await http.post("/api/createInterestedUser", body);
      dispatch({
        type: CREATE_INTERESTED_USER,
        payload: { list: data },
      });
      cb();
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: { message: `storage error: ${error}` },
      });
      console.log("Some Error Occured in Getting the User ID !", error);
    }
  };

// Function to Get the User by ID
const getUserInteresUserBytId =
  (body, cb = () => {}) =>
  async (dispatch) => {
    try {
      const { data } = await http.post("/api/getUserInterestById", body);
      dispatch({
        type: GET_USER_INTEREST,
        payload: { list: data },
      });
      cb(data);
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: { message: `storage error: ${error}` },
      });
      console.log("Some Error Occured in Getting the User ID !", error);
    }
  };

export default {
  getCompanyDetail,
  sendOtp,
  verifyOtp,
  createUser,
  loginUser,
  logoutUser,
  getUser,
  getUserById,
  updateUser,
  resetOtp,
  saveUserQuery,
  leadCapture,
  websiteClickCapture,
  getCompanyNomination,
  saveUserVote,
  resetVote,
  saveInterestedUser,
  getUserInteresUserBytId,
};
