import { React, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import backArrow from "../../../../assets/img/arrowBack.png";
import _brand from "../../../../assets/json/brand.json";
import _product from "../../../../assets/json/product.json";
import _launch from "../../../../assets/json/launch.json";

import logo from "../../../../assets/img/product_logo.png";
import product from "../../../../assets/img/winner2.png";

function AllWinners() {
 
  return (
    <section className="bg-brown winnerCategory h-100">
      <div className="container">
      <div className="inner_winner_title">
            <div className="winners_years">
              <span>2021</span>
              <span>2022</span>
            </div>
            <h1 className="text-white">Winners  <span className="active_year">2022</span></h1> 
          </div>   

      


        <div className="winnerTitle">
          <h4>
             Product of the year
          </h4>
        </div>

        <div className="products h-auto mb-5">
         
            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
                <img src='https://awards.channelier.com/uploads/winner/launch_logo/239.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/239.png' alt="product img" />
              </div>
              <span>
              Saridon
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
                 <img src='https://awards.channelier.com/uploads/winner/launch_logo/89.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/89.png' alt="product img" />
              </div>
              <span>
                  Max Protein Choco Chips Cookies
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

       
            
        
         
        </div>

        <div className="winnerTitle">
                  
          
          <h4>
             Product launch of the year
          </h4>
         
        </div>
        
        <div className="products h-auto mb-5">
         
            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
                <img src='https://awards.channelier.com/uploads/winner/launch_logo/239.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/239.png' alt="product img" />
              </div>
              <span>
              Saridon
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
                 <img src='https://awards.channelier.com/uploads/winner/launch_logo/89.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/89.png' alt="product img" />
              </div>
              <span>
                  Max Protein Choco Chips Cookies
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            

            
            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
                <img src='https://awards.channelier.com/uploads/winner/launch_logo/239.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/239.png' alt="product img" />
              </div>
              <span>
              Saridon
              </span>
            </div>

            <div className="productImg">
              <div className="brandLogoVote">
                 <img src='https://awards.channelier.com/uploads/winner/launch_logo/89.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/89.png' alt="product img" />
              </div>
              <span>
                  Max Protein Choco Chips Cookies
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
                 <img src='https://awards.channelier.com/uploads/winner/launch_logo/89.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/89.png' alt="product img" />
              </div>
              <span>
                  Max Protein Choco Chips Cookies
              </span>
            </div>
         
        </div>

        <div className="winnerTitle">
          <h4>
             Brand of the year
          </h4>
         
        </div>
        
        <div className="products h-auto">
         
            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
                <img src='https://awards.channelier.com/uploads/winner/launch_logo/239.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/239.png' alt="product img" />
              </div>
              <span>
              Saridon
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
                 <img src='https://awards.channelier.com/uploads/winner/launch_logo/89.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/89.png' alt="product img" />
              </div>
              <span>
                  Max Protein Choco Chips Cookies
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            <div className="productImg">
              <div className="brandLogoVote">
              
                <img src={logo} alt="productLogo" />
              </div>
              <div className="productImgVote">
              
                <img src={product} alt="product img" />
              </div>
              <span>
               Yu Foodlabs Three Cheese Instant Pasta
              </span>
            </div>

            <div className="productImg">
              <div className="brandLogoVote">
                 <img src='https://awards.channelier.com/uploads/winner/launch_logo/89.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/89.png' alt="product img" />
              </div>
              <span>
                  Max Protein Choco Chips Cookies
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
                <img src='https://awards.channelier.com/uploads/winner/launch_logo/239.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/239.png' alt="product img" />
              </div>
              <span>
              Saridon
              </span>
            </div>

            
            <div className="productImg">
              <div className="brandLogoVote">
                 <img src='https://awards.channelier.com/uploads/winner/launch_logo/89.png' alt="productLogo" />
              </div>
              <div className="productImgVote">
                <img src='https://awards.channelier.com/uploads/winner/launch/89.png' alt="product img" />
              </div>
              <span>
                  Max Protein Choco Chips Cookies
              </span>
            </div>
         
        </div>

        </div>
    </section>

  );
}

export default AllWinners;
