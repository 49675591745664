// Importing the requirements
import React from "react";
import { NavItem } from "react-bootstrap";
import logo from "../../assets/img/logo-channelier.png";
import { FaUser, FaAward, FaPlus } from "react-icons/fa";
// import { FaUser, FaShoppingCart, FaPlus, FaAward,FaShare } from 'react-icons/fa';
import { NavLink } from "react-router-dom";

// Sidebar Function
function Sidebar() {
  // Sidebar UI
  return (
    <div className="sidebar">
      <div className="sidebar_logo">
        <img src={logo} alt="logo" className="logo" />
      </div>

      <NavItem>
        <NavLink to="/auth/profile" className="nav-link">
          <span>
            <FaUser />
          </span>
          <span>Profile</span>
        </NavLink>

        <NavLink to="/auth/addnominations" className="nav-link">
          <span>
            <FaPlus />
          </span>
          <span>Add Nominations</span>
        </NavLink>
        {/* 
        <NavLink to="/auth/checkout" className="nav-link">
          <span>
            <FaShoppingCart />
          </span>
          <span>Cart</span>
        </NavLink> */}

        <NavLink to="/auth/nominated" className="nav-link">
          <span>
            <FaAward />
          </span>
          <span>Nominated</span>
        </NavLink>

        {/* <NavLink to="/auth/template"  className="nav-link">
         <span><FaShare /></span>
         <span>Share</span>
        </NavLink> */}
      </NavItem>
    </div>
  );
}

export default Sidebar;
