// Importing the Requirements
import { React, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import fb from "../../assets/img/social_facebook.png";
import ig from "../../assets/img/social_instagram.png";
import twitter from "../../assets/img/social_twitter.png";
import wapp from "../../assets/img/social_whatsapp.png";
import linkedin from "../../assets/img/social_linkedin.png";
import domtoimage from "dom-to-image";
import Collapse from "react-bootstrap/Collapse";
import user from "../../store/action/user";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import http from "../../services/HttpClient";
import shortid from "shortid";

// Function to Display the Profile details
function Template() {
  const { state } = useLocation();
  const { companyName, slugLink } = state;
  const [showTemplate1, setShowTemp1] = useState(false);
  const [showTemplate2, setShowTemp2] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const dispatch = useDispatch();
  let selectedTemplateName = "";
  let templateName = "";

  const companyData = useSelector((state) => state.user.companyDetail);
  const nominationdata = useSelector((state) => state.user.nominationData);
  const startDate = "2021-12-31T18:30:00.000Z";
  const endDate = "2022-12-30T18:30:00.000Z";

  console.log(nominationdata);
  useEffect(() => {
    dispatch(user.getCompanyDetail({ name: companyName }));
  }, []);

  useEffect(() => {
    if (companyData && Object.keys(companyData).length > 0) {
      dispatch(
        user.getCompanyNomination({
          userId: companyData.companyId || companyData._id,
          startDate,
          endDate,
        })
      );
    }
  }, [companyData]);

  const getAwardName = (award) => {
    if (award == "Product of the year") {
      return "product";
    }
    if (award == "Product Launch of the year") {
      return "launch";
    }
    if (award == "Brand of the year") {
      return "brand";
    }
  };

  const openUrl = async (shareUrl, data) => {
    let pageUrl = `https://${
      window.location.host
    }/company-vote/${selectedTemplateName}/?v=${shortid.generate()}`;
    let url = `${shareUrl}${pageUrl}`;
    window.open(
      url,
      "_blank",
      "toolbar=yes,top=100,left=500,width=400,height=400"
    );
  };

  const generateTemplate = (id, data) => {
    let node = document.getElementById(id);
    domtoimage
      .toPng(node)
      .then(async function (dataUrl) {
        let img = new Image();
        img.src = dataUrl;
        let company = data?.user[0].companyName;
        let companyName = company.replace(/\s+/g, "-");
        let awardName = data?.award[0].awardName;
        selectedTemplateName = `${companyName}-${getAwardName(
          awardName
        )}-${id}`;

        let url = `https://${window.location.hostname}/company-vote/${selectedTemplateName}`;

        let imageUrl = `https://${window.location.host}/uploads/mailFiles/${selectedTemplateName}.jpeg`;

        const uploadImageRes = await http.post("/api/upload-template", {
          base64Image: dataUrl,
          fileName: selectedTemplateName,
        });
        const uploadTemplate = await http.post(`/api/upload-share-template`, {
          image: imageUrl,
          award: awardName,
          template: selectedTemplateName,
          company: data?.user[0].companyName,
          url,
        });

        // template.appendChild(img);
        // var link = document.createElement('a');
        // link.download = 'my-image-name.jpeg';
        // link.href = dataUrl;
        // link.click();
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const getImageSource = (imageLink) => {
    if (imageLink) {
      const imageLinkChunks = imageLink.split("/");
      return `https://${window.location.hostname}/uploads/${
        imageLinkChunks[imageLinkChunks.length - 1]
      }`;
    }
  };

  const getImageForSeo = (data) => {
    let imageLink = "";
    if (
      data?.userProfile.length &&
      typeof data?.userProfile[0]?.productImage !== "string" &&
      data?.userProfile[0]?.productImage?.image !== "" &&
      data?.userProfile[0]?.productImage?.image !== "null" &&
      data?.userProfile[0]?.productImage?.image !== null
    ) {
      if (data.award[0].awardName !== "Brand of the year") {
        imageLink = getImageSource(data?.userProfile[0]?.productImage?.image);
      } else {
        if (
          data?.userProfile.length &&
          typeof data?.userProfile[0]?.brandLogo !== "string" &&
          data?.userProfile[0]?.brandLogo?.image !== "" &&
          data?.userProfile[0]?.brandLogo?.image !== "null" &&
          data?.userProfile[0]?.brandLogo?.image !== null
        ) {
          imageLink = getImageSource(data?.userProfile[0]?.brandLogo?.image);
        }
      }
    }

    if (imageLink !== "") {
      return imageLink;
    }

    return `https://${window.location.hostname}/uploads/awards_logo.png`;
  };

  const ToggleItem = ({ data, id }) => {
    const [open, setToggleThisElement] = useState(false);

    return (
      <div key={id}>
        <div className="temp-layout">
          <div className="section-title text-center mt-5 mb-3">
            <h2> {data.award[0].awardName} - Template 1</h2>
          </div>
          <Button
            className="custom_btn brown_btn me-3"
            onClick={() => {
              generateTemplate(`template-${id}`, data);
            }}
          >
            Use Template 1
          </Button>
          <br /> <small>*Click on the button to select this template</small>
          <div className="votingShareContainer mt-2" id={`template-${id}`}>
            <img
              src={`https://${window.location.hostname}/uploads/bannervoting.png`}
              className="votingbanner"
              alt="banner"
            />
            <div className="votebannerContent">
              <img src={getImageForSeo(data)} alt="product" />
              <h2>we've been nominated</h2>
              <p>Category : {data.award[0].awardName}</p>
              <h1>vote for us</h1>
            </div>
          </div>
          <div
            id={`example-collapse-text-${id}`}
            className="shareProdDiv"
            key={id}
          >
            <div className="share">
              <ul>
                <li>
                  <a>
                    <img
                      src={fb}
                      alt="fb"
                      onClick={() =>
                        openUrl(
                          "https://www.facebook.com/sharer/sharer.php?u=",
                          data
                        )
                      }
                    />
                  </a>
                </li>
                {/* <li>
                                        <a><img src={ig} alt="insta"
                                            onClick={() => openUrl('https://www.instagram.com?text=', data)} /></a>
                                    </li> */}
                <li>
                  <a>
                    <img
                      src={twitter}
                      alt="twitter"
                      onClick={() =>
                        openUrl("https://twitter.com/intent/tweet/?url=", data)
                      }
                    />
                  </a>
                </li>
                <li>
                  <a>
                    <img
                      src={wapp}
                      alt="whatsapp"
                      onClick={() =>
                        openUrl("https://api.whatsapp.com/send?text=", data)
                      }
                    />
                  </a>
                </li>
                <li>
                  <a>
                    <img
                      src={linkedin}
                      alt="linkedin"
                      className="linkedin-img"
                      onClick={() =>
                        openUrl(
                          "http://www.linkedin.com/shareArticle?mini=true&url=",
                          data
                        )
                      }
                    />
                  </a>
                </li>
              </ul>
            </div>
            <hr />
          </div>
        </div>

        <div className="temp-layout">
          <div className="section-title text-center mt-5 mb-3">
            <h2> {data.award[0].awardName} - Template 2</h2>
          </div>
          <Button
            className="custom_btn brown_btn"
            onClick={() => {
              generateTemplate(`template2-${id}`, data);
            }}
          >
            Use Template 2
          </Button>
          <br /> <small>*Click on the button to select this template</small>
          <div
            className="votingShareContainer template2 mt-2"
            id={`template2-${id}`}
          >
            <img
              src={`https://${window.location.hostname}/uploads/template2.jpg`}
              className="votingbanner"
              alt="banner"
            />

            <div className="votebannerContent">
              <img
                src={`https://${window.location.hostname}/uploads/awards_logo.png`}
                alt="product"
              />
              <h2>we've been nominated</h2>
              <p>Category : {data.award[0].awardName}</p>
              <h1>vote for us</h1>
            </div>
            <img src={getImageForSeo(data)} className="prodImg" alt="product" />
          </div>
          <div
            id={`example-collapse-text-${id}`}
            className="shareProdDiv"
            key={id}
          >
            <div className="share">
              <ul>
                <li>
                  <a>
                    <img
                      src={fb}
                      alt="fb"
                      onClick={() =>
                        openUrl(
                          "https://www.facebook.com/sharer/sharer.php?u=",
                          data
                        )
                      }
                    />
                  </a>
                </li>
                {/* <li>
                                        <a><img src={ig} alt="insta"
                                            onClick={() => openUrl('https://www.instagram.com?text=', data)} /></a>
                                    </li> */}
                <li>
                  <a>
                    <img
                      src={twitter}
                      alt="twitter"
                      onClick={() =>
                        openUrl("https://twitter.com/intent/tweet/?url=", data)
                      }
                    />
                  </a>
                </li>
                <li>
                  <a>
                    <img
                      src={wapp}
                      alt="whatsapp"
                      onClick={() =>
                        openUrl("https://api.whatsapp.com/send?text=", data)
                      }
                    />
                  </a>
                </li>
                <li>
                  <a>
                    <img
                      src={linkedin}
                      alt="linkedin"
                      className="linkedin-img"
                      onClick={() =>
                        openUrl(
                          "http://www.linkedin.com/shareArticle?mini=true&url=",
                          data
                        )
                      }
                    />
                  </a>
                </li>
              </ul>
            </div>
            <hr />
          </div>
        </div>
      </div>
    );
  };

  // Profile UI
  return (
    <>
      <div className="nominee_workflow">
        <h4 className="mb-4">Template Share Settings</h4>
        <div className="personal_details">
          <div className="inner_form">
            {/* <div>
                            <Button className="custom_btn brown_btn me-3" onClick={() =>{setShowTemp1(!showTemplate1);generateTemplate('template')}}>Use Template 1</Button>
                            <Button className="custom_btn brown_btn" onClick={() => {setShowTemp2(!showTemplate2);generateTemplate('template2')}}>Use Template 2</Button>
                        </div> */}
          </div>
        </div>

        {/* <div className="nominationShareBtn" > */}
        {nominationdata.map((data, index) => (
          <ToggleItem id={index} data={data} />
        ))}
        {/* </div> */}

        <div id="template-img"></div>
      </div>
    </>
  );
}

// Exporting Profile UI
export default Template;
