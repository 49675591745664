import { Helmet } from 'react-helmet';
import React from 'react';
const Seo = ({ title, description, image, pathSlug }) => {
    const url = pathSlug
    let endPoint=window.location.pathname.split('/');
    endPoint=endPoint[2];

    return (
        <Helmet htmlAttributes={{ lang: 'en' }} title={title} meta={[
            {
                name: 'title',
                content: title,
            },
            {
                name: 'description',
                content: description,
            },
            {
                name: 'og:title',
                content: title,
            },
            {
                name: 'og:description',
                content: description
            },
            {
                name: 'og:url',
                content: `${window.location.host}/vote/${endPoint}`,
            },
            {
                name: 'og:type',
                content: 'article',
            },
            {
                name: 'og:image',
                content: image,
            },
            {
                name: 'twitter:description',
                content: description
            },
            {
                name: 'twitter:title',
                content: title
            },
            {
                name: 'twitter:image',
                content: image
            }


        ]}
            links={[
                {
                    rel: 'canonical',
                    href: url,
                },
            ]}
        />
    );
}
export default Seo;