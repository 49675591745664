import { React } from "react";
import { Link } from "react-router-dom";
import category1 from "../../assets/img/new1.png";
import category2 from "../../assets/img/new2.png";
import category3 from "../../assets/img/new3.png";

function WinnersCategories() {
  return (
    <section className="winnerCategory" id="2022">
      <div className="container">
        <div className="section_title ps-0">
          <h2>Winners 2022</h2>
        </div>

        <div className="winnerRow">
          <div className="winnerColumn">
            <Link to="/winnerdetails?category=launch">
              <img src={category1} alt="img" />
              <h4>Product Launch of the year</h4>
            </Link>
          </div>

          <div className="winnerColumn">
            <Link to="/winnerdetails?category=brand">
              <img src={category3} alt="img" />
              <h4>Brand of the year</h4>
            </Link>
          </div>

          <div className="winnerColumn">
            <Link to="/winnerdetails?category=product">
              <img src={category2} alt="img" />
              <h4>Product of the year</h4>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WinnersCategories;
