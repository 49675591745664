// Importing the Requirements
import { React, useState, useEffect } from "react";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import user from "../../store/action/user";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../services/localStorage";
import { Link, useNavigate } from "react-router-dom";
import { FiXCircle, FiEdit } from "react-icons/fi";
import fb from "../../assets/img/social_facebook.png";
import ig from "../../assets/img/social_instagram.png";
import twitter from "../../assets/img/social_twitter.png";
import linkedin from "../../assets/img/social_linkedin.png";
import http from "../../services/HttpClient";
import shortid from "shortid";

import wapp from "../../assets/img/social_whatsapp.png";
import domtoimage from "dom-to-image";
import Collapse from "react-bootstrap/Collapse";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import default company logo Image;
import defaultCompanyLogo from "../../assets/img/default_place_holder.webp";

// Function to Display the Profile details
function Profile() {
  const [showA, setShowA] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [base64, setBase64] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [validated, setValidated] = useState(false);
  const [slugLink, setSlugLink] = useState("");
  const [voteLink, setVoteLink] = useState("");
  const userProfile = useSelector((state) => state.user.userProfile);
  const userDetail = useSelector((state) => state.user.userDetail);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleShowA = () => setShowA(!showA);
  useEffect(() => {
    if (userDetail.isMobileUpdated === true) {
      navigate("/login", userDetail);
    }
  });

  // useEffect for checking user is logged in or not
  useEffect(() => {
    if (userDetail.token && userDetail.token !== null) {
      return;
    }
    navigate("/login");
  }, []);

  // Use Effect for Getting the current logged in user from local storage
  useEffect(() => {
    // Getting the user Id from local storage
    try {
      const token = storage.getUserId();
      if (token && token !== "undefined") {
        dispatch(user.getUserById({ _id: token }));
      } else {
        navigate("/login");
      }
    } catch (err) {
      navigate("/login");
      console.log("Error in getting the user id from local storage", err);
    }
  }, []);
  // Use Effect for Setting each field of the New Registering Company
  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile.userData?.name);
      setEmail(userProfile.userData?.email);
      setPhone(userProfile.userData?.phone);
      setCompanyName(userProfile.userData?.companyName);
      setCompanyWebsite(userProfile.userData?.website);
      setBase64Image(userProfile.userData?.companyLogo);
      setBase64(userProfile.userData?.companyLogo);
      if (userProfile.votingSlugLink?.slugLink) {
        setSlugLink(userProfile.votingSlugLink?.slugLink);
        setVoteLink(
          `https://${window.location.hostname}/vote/${userProfile.votingSlugLink?.slug}`
        );
      } else {
        let companyName = userProfile.userData?.companyName.replace(
          /\s+/g,
          "-"
        );
        setSlugLink(
          `https://${window.location.hostname}/voting/${companyName}`
        );
        setVoteLink(`https://${window.location.hostname}/vote/${companyName}`);
      }
    }
  }, [userProfile, userProfile.userData]);

  // Function to update the Company profile
  const updateUser = () => {
    let formData = new FormData();
    formData.append("companyLogo", base64);
    formData.append("url", window.location.hostname);
    formData.append("name", firstName);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("companyName", companyName);
    formData.append("website", companyWebsite);
    formData.append("tandc", "on");
    formData.append("id", storage.getUserId());

    let oldNumber =
      typeof userDetail.user === "undefined"
        ? userDetail.phone
        : userDetail.user.phone;
    let newNumber = phone;

    // Previous and New Phone Numbers are same
    if (oldNumber === newNumber) {
      dispatch(user.updateUser(formData, notification));
    }

    // Previous and New Phone Numbers are different
    else {
      dispatch(user.updateUser(formData));
    }
  };

  // Function to Handle Submit Action
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) event.stopPropagation();
    else updateUser();

    setValidated(true);
  };

  const Required = (string) => {
    return (
      <span>
        {string}
        <span className="required">*</span>
      </span>
    );
  };

  // Function to navigate to the add nomination page
  const notification = () => {
    toast.success("Profile Updated Successfully!", {
      autoClose: 1000,
    });
    setTimeout(() => navigate("/auth/addnominations"), 2000);
  };

  function getFileExtension(filename) {
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts.pop();
    } else {
      return null;
    }
  }

  // Function to upload the files (Here, the Company Logo)
  const readUploadFile = (event) => {
    const fileName = event.target.files[0].name;

    let fileSizeInMB = event.target.files["0"].size / 1024;
    fileSizeInMB = fileSizeInMB / 1024;

    // let fileFormat = event.target.files["0"].name.split(".")[1];
    let fileFormat=getFileExtension(fileName).toLowerCase();


    // Only following types of files are supported
    if (fileFormat !== "jpg" && fileFormat !== "png" && fileFormat !== "jpeg") {
      toast.warn("File format not supported !", {
        autoClose: 1000,
      });
      return;
    }

    // Restricting the size of the file
    if (fileSizeInMB > 10) {
      toast.warn("Maximum file size allowed is 10MB !", {
        autoClose: 1000,
      });
      return;
    }

    // To prevent the default events
    event.preventDefault();
    if (event.target.files) {
      setBase64(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        setBase64Image(data);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const openUrl = (shareUrl) => {
    alert(shortid.generate());
    // let pageUrl = slugLink
    // let url = `${shareUrl}${pageUrl}/?v=${shortid.generate()}`;
    // window.open(
    //   url,
    //   "_blank",
    //   "toolbar=yes,top=100,left=500,width=400,height=400"
    // );
  };

  const copyLink = (id) => {
    let copyText = document.getElementById(id);
    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  };

  const generaeProductLink = async () => {
    setOpen(!open);
    const uploadProductTemplate = await http.post(
      "/api/upload-product-template",
      {
        logo: userProfile.userData?.companyLogo,
        slugLink,
        company: userProfile.userData?.companyName,
      }
    );
    console.log(uploadProductTemplate);
  };

  // Profile UI
  return (
    <>
      <div className="nominee_workflow">
        <ToastContainer />

        <h4 className="mb-4">My Profile</h4>

        <div className="personal_details">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="inner_form">
              {/* Name */}
              <FloatingLabel
                controlId="floatingInput"
                label={Required("Name")}
                className="mb-4"
              >
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter your Name
                </Form.Control.Feedback>
              </FloatingLabel>

              {/* Email */}
              <FloatingLabel
                controlId="floatingInput"
                label={Required("Email address")}
                className="mb-4"
              >
                <Form.Control
                  required
                  type="text"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter your Email
                </Form.Control.Feedback>
              </FloatingLabel>

              {/* Phone Number */}
              <FloatingLabel
                controlId="floatingInput"
                label={Required("Mobile number")}
                className="mb-4"
              >
                <Form.Control
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  placeholder="Mobile Number"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter your Mobile Number
                </Form.Control.Feedback>
              </FloatingLabel>

              {/* Company Name */}
              <FloatingLabel
                controlId="validationCustom03"
                label={Required("Company name")}
                className="mb-4"
              >
                <Form.Control
                  required
                  type="text"
                  placeholder="Company name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter your Company Name
                </Form.Control.Feedback>
              </FloatingLabel>

              {/* Company Website */}
              <FloatingLabel
                controlId="floatingInput"
                label={Required("Company website")}
                className="mb-4"
              >
                <Form.Control
                  required
                  value={companyWebsite}
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                  type="text"
                  placeholder="Company Website"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter your Company Website
                </Form.Control.Feedback>
              </FloatingLabel>

              {/* File Upload */}
              <Form.Group
                controlId="companylogo"
                className="mb-4 custom_file text-center"
              >
                <Form.Label>Upload Company Logo ( jpg, png, jpeg ).</Form.Label>
                <Form.Control type="file" onChange={(e) => readUploadFile(e)} />
              </Form.Group>
              {base64Image && base64Image !== "" ? (
                <div className="user_img text-center mb-4">
                  <img src={base64Image} className="img_icon" alt="user" />
                </div>
              ) : (
                <div className="user_img text-center mb-4">
                  <img
                    src={defaultCompanyLogo}
                    className="img_icon"
                    alt="user"
                  />
                </div>
              )}
              {/* 
              <div>
                <label className="mb-1">
                  <strong>
                    Voting Link <small>(Click to copy)</small>{" "}
                  </strong>{" "}
                </label>
                <FloatingLabel controlId="floatingInput" className="mb-4">
                  <Form.Control
                    className="cursor-pointer"
                    id="votingSeoLink"
                    value={slugLink}
                    type="text"
                    readOnly={true}
                    onClick={() => copyLink("votingSeoLink")}
                  />
                </FloatingLabel>
              </div> */}
              <div className="form_btns text-center">
                <Button type="button" className="custom_btn cancel_btn me-2">
                  Cancel
                </Button>
                <Button type="submit" className="custom_btn brown_btn">
                  Save
                </Button>
              </div>

              {/* <div>
                <Collapse in={open} className="mt-5">
                  <div id="example-collse-text">
                    <div className="share">
                      <ul>
                        <li>
                          <a>
                            <img
                              src={fb}
                              alt="fb"
                              onClick={() =>
                                openUrl(
                                  "https://www.facebook.com/sharer/sharer.php?u="
                                )
                              }
                            />
                          </a>
                        </li>
                        <li>
                          <a><img src={ig} alt="insta"
                            onClick={()=>openUrl('https://www.instagram.com?text=')} /></a>
                        </li>
                        <li>
                          <a>
                            <img
                              src={twitter}
                              alt="twitter"
                              onClick={() =>
                                openUrl(
                                  "https://twitter.com/intent/tweet/?url="
                                )
                              }
                            />
                          </a>
                        </li>
                        <li>
                          <a>
                            <img
                              src={wapp}
                              alt="whatsapp"
                              onClick={() =>
                                openUrl("https://api.whatsapp.com/send?text=")
                              }
                            />
                          </a>
                        </li>
                        <li>
                          <a>
                            <img
                              src={linkedin}
                              alt="linkedin"
                              className="linkedin-img"
                              onClick={() =>
                                openUrl(
                                  "https://www.linkedin.com/shareArticle?mini=true&url="
                                )
                              }
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Collapse>

                <div className="form_btns text-center">
                  <Button
                    className="custom_btn brown_btn  me-3"
                    onClick={() => generaeProductLink()}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    type="button"
                  >
                    Share Link
                  </Button>
                  <Button
                    className="custom_btn brown_btn"
                    type="button"
                    onClick={() =>
                      navigate("/auth/template", {
                        state: { slugLink, companyName },
                      })
                    }
                  >
                    Share Template
                  </Button>
                </div>
              </div> */}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

// Exporting Profile UI
export default Profile;
