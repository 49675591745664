import React, { useState } from "react";
import data from "./data.json";
import InfoCard from "../../components/InfoCard/InfoCard";

const Evaluation_Parameter = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <section className="evaluation_container">
      <div className="section_title">
        <h2>Evaluation Parameter</h2>
      </div>
      <div className="container-fluid">
        <div className="evaluation_container_box">
          <div className="evaluation_tab_navigation">
            {data?.data?.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => setSelectedTab(index)}
                  className={`evaluation_tab_btns ${
                    selectedTab === index && "evaluation_tab_btns_active"
                  }`}
                >
                  {item?.Category_title}
                </button>
              );
            })}
          </div>
          <div className="evaluation_tab_data">
            {data?.data?.[selectedTab]?.data?.map((item, index) => {
              return (
                <InfoCard data={item} key={index} primary_color={"#000000"} />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Evaluation_Parameter;
