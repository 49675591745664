import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import backArrow from '../../assets/img/arrowBack.png';
import user from '../../store/action/user';
import LoginVotePopup from './loginVotePopup';
import Seo from '../Seo/seo';



function CompanyVote() {
    const searchParams = useParams()
    const { name } = searchParams
    const dispatch = useDispatch()
    // const [selectedVoteCOunt, setSelectedVoteCount] = useState(0)
    // const [selectedNomination,setSelectedNomination]=useState([]);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const companyData = useSelector((state) => state.user.companyDetail)
    const nominationdata = useSelector(state => state.user.nominationData)
    const startDate = "2021-12-31T18:30:00.000Z";
    const endDate = "2022-12-30T18:30:00.000Z";

console.log(nominationdata);
    useEffect(() => {
        dispatch(user.getCompanyDetail({ name }))
    }, [])

    useEffect(() => {
        if (companyData&&Object.keys(companyData).length>0) {
            dispatch(user.getCompanyNomination({ userId: (companyData.companyId||companyData._id),startDate,endDate }))
        }
        
    }, [companyData])

  

    const getImageSource = (imageLink) => {
        if (imageLink) {
          const imageLinkChunks = imageLink.split('/');
    
          return `https://awards.channelier.com/uploads/${imageLinkChunks[imageLinkChunks.length - 1]}`
        }
      }

      const getImageForSeo=()=>{
        let imageLink="";
        nominationdata &&  nominationdata.forEach(data => {
            if (data?.userProfile.length && typeof(data?.userProfile[0]?.productImage)!=="string" && data?.userProfile[0]?.productImage?.image!==""&& data?.userProfile[0]?.productImage?.image!=="null" &&  data?.userProfile[0]?.productImage?.image!==null)
            {
                imageLink= getImageSource(data?.userProfile[0]?.productImage?.image)
            } else{
                if (data?.userProfile.length && typeof(data?.userProfile[0]?.brandLogo)!=="string" && data?.userProfile[0]?.brandLogo?.image!==""&& data?.userProfile[0]?.brandLogo?.image!=="null" &&  data?.userProfile[0]?.brandLogo?.image!==null) {
                    imageLink= getImageSource(data?.userProfile[0]?.brandLogo?.image)
                }

            }
        });
        if (imageLink!=="") {
            return imageLink
        }
        
         return "https://awards.channelier.com/static/media/about.56460027e2233779f4f9.png"
        
        
      }

    //   const selectNomination = (e, index,data) => {
    //     const selecteNomination=document.getElementById(index);
    //     const token=selecteNomination.classList.toggle('activeVote')
    //     console.log(token);
    //     if (!token) {
    //         setSelectedVoteCount((prevCount) => prevCount - 1)
    //         setSelectedNomination(selectedNomination.filter(item=>item._id!==data._id))
    //         return
    //     }
    //     setSelectedVoteCount((prevCount) => prevCount + 1)
    //     setSelectedNomination((prevNomination)=>[...prevNomination,data])


    // }

    return (
        <>
        
            <section className='bg-brown votePage'>
            <Seo
            title={nominationdata[0]?.user[0]?.companyName}
            description={"You are invited to vote for"+nominationdata[0]?.user[0]?.companyName}
            image={getImageForSeo()}
            pathSlug={window.location.href}
            
            />{nominationdata.length ?
                <div className='container'>
                    <div className='voteTitle'>
                        <h4> <img src={backArrow} alt='icon' />{nominationdata[0]?.user[0]?.companyName} </h4>
                    </div>
                    <h3 className='text-white mb-3 mt-5'>You have been Invited to vote for</h3>
                    <div className='companyVoting'>
                        {nominationdata && nominationdata.map((data, index) =>
                            <div className='companyVote' key={index} id={index}>
                                {data?.userProfile.length && typeof(data?.userProfile[0]?.brandLogo)!=="string" && data?.userProfile[0]?.brandLogo?.image!==""&& data?.userProfile[0]?.brandLogo?.image!=="null" &&  data?.userProfile[0]?.brandLogo?.image!==null?
                                <img className='companyVoteBrand' src={getImageSource(data?.userProfile[0]?.brandLogo?.image)} alt='' />
                            :<p className='companyVoteBrand text-start w-100'>{data?.user[0].companyName}</p>}
                                
                                {data?.userProfile.length && typeof(data?.userProfile[0]?.productImage)!=="string" && data?.userProfile[0]?.productImage?.image!==""&& data?.userProfile[0]?.productImage?.image!=="null" &&  data?.userProfile[0]?.productImage?.image!==null?
                                <img className='companyVotePrdct' src={getImageSource(data?.userProfile[0]?.productImage?.image)} alt='' />
                            : <img className='companyVotePrdct' src={require('../../assets/img/placeholder.webp')} alt='' />}

                                <p className='pductName'>{data?.productName}</p>
                                <p className='catgryName'>{data?.award[0]?.awardName}</p>
                              

                            </div>
                        )}
                    </div>
                    <div className='voteButton'>
                        <button type='button' className='submitVote hvr-shutter-out-vertical' onClick={handleShow}>
                            <span className='triangleShape'></span>
                            Submit vote
                        </button>
                    </div>
                </div>
                : <div className='container text-white text-large'>   <div className='companyVoting'>
            There are no nominations for this company</div> </div>}       
                   
                
            </section>
            <LoginVotePopup   show={show} showHandler={setShow} nominationData={nominationdata}/>


        </>
    );


}

export default CompanyVote
