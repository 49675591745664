import React, { useCallback, useEffect, useRef, useState } from 'react';
import leftarrow from '../../../../assets/img/leftarrow.png';
import rightarrow from '../../../../assets/img/rightarrow.png';
import podium from '../../../../assets/img/webp/product_table.webp';
import product1 from '../../../../assets/img/winners/product_year/1.png'
import product2 from '../../../../assets/img/winners/product_year/2.png'
import product3 from '../../../../assets/img/winners/product_year/3.png'
import './Winners.css';


function Winnners() {

  const [productImageSet, setProductImageSet] = useState([
    product1, product2, product3
  ]);

  // This state changes the postion of items in a winner category
  const [atFront, setAtFront] = useState(0);
  // This state handles which category to show
  const [category, setCategory] = useState(0);
  //This state changes onclicking the 2021 and 2020 buttons, the data changes upon clicking.'
  const atFrontref = useRef(0);
  let intervalId = 0;
  let spliceIndexi = 0


  function getAllImages(c) {

    if (c === 0) {
      return importAll(require.context("../../../../assets/img/winners/product_year/webp", false, /\.(png|jpe?g|svg|webp)$/));
    }
    if (c === 1) {
      return importAll(require.context("../../../../assets/img/winners/product_launch/webp", false, /\.(png|jpe?g|svg|webp)$/));
    }
    if (c === 2) {
      return importAll(require.context("../../../../assets/img/winners/brand_year/webp", false, /\.(png|jpe?g|svg|webp)$/));
    }

  }

  function importAll(r) {
    return r.keys().map(r);
  }

  const rotateimage = (c) => {
    // console.log(c);
    let newPeod = []
    let allImages = getAllImages(c)
    if (c === 0 && spliceIndexi > 33) {
      spliceIndexi = 0
    }
    if (c === 1 && spliceIndexi > 21) {
      spliceIndexi = 0
    }
    if (c === 2 && spliceIndexi > 23) {
      spliceIndexi = 0
    }
    for (let index = spliceIndexi; index < spliceIndexi + 3; index++) {
      const imageUrl = allImages[index];
      newPeod.push(imageUrl)
    }
    setProductImageSet(newPeod);
    spliceIndexi += 3;
  }

  const startInterval = (cat) => {
    intervalId = setInterval(() => {
      handleFront();
      // console.log(category, "Dafadsfasfd");
      rotateimage(cat);
    }, 4000);
  }

  useEffect(() => {
    startInterval(category);

    return () => clearInterval(intervalId);
  }, [category]);

  // This function handles the cycle of item in a category
  const handleFront = useCallback(() => {
    if (atFrontref.current === 2) {
      atFrontref.current = 0;
    } else {
      atFrontref.current += 1;
    }
    setAtFront(atFrontref.current);
  }, []);

  //This function handles the click on arrow

  const handleClick = (cat) => {
    spliceIndexi = 0
    clearInterval(intervalId);
    if (cat > 2) {
      setCategory(0);
      return
    }
    if (cat < 0) {
      setCategory(2);
      return
    }
    setCategory(cat);
   
  };



  // This component contains three products cycling at an interval for a single winner category
  const WinnerProducts = ({ frontToken = 0, imageData = [] }) => {
    // console.log(imageData,"Adasdas");
    const tokenCalculator = () => {
      let prevToken, nextToken;
      if (frontToken === 0) {
        prevToken = 2;
        nextToken = 1;
      } else if (frontToken === 1) {
        prevToken = 0;
        nextToken = 2;
      } else {
        prevToken = 1;
        nextToken = 0;
      }

      return { prevToken, nextToken };
    };
    const { prevToken: prev, nextToken: next } = tokenCalculator();

    return (
      <div
        className={
          'winnerProductsMain ' +
          (category === prev
            ? 'prevWinner'
            : category === next
              ? 'nextWinner'
              : '')
        }
      >
        <div
          className={
            'innitialFront ' +
            (atFront === 0
              ? 'atFront'
              : atFront === 1
                ? 'atLeftBack'
                : atFront === 2
                  ? 'atRightBack'
                  : '')
          }
        >
          <img
            src={imageData[1]}
            // style={{ height: '20vh' }}
            alt='product'
            className='fadeInUp_anime'
          />
          <img src={podium} alt='podium' />
        </div>
        <div
          className={
            'innitialFront ' +
            (atFront === 1
              ? 'atFront'
              : atFront === 2
                ? 'atLeftBack'
                : atFront === 0
                  ? 'atRightBack'
                  : '')
          }
        >
          <img
            src={imageData[2]}
            // style={{ height: '16vh' }}
            alt='product '
            className='fadeInUp_anime'
          />
          <img src={podium} alt='podium' />
        </div>
        <div
          className={
            'innitialFront ' +
            (atFront === 2
              ? 'atFront'
              : atFront === 0
                ? 'atLeftBack'
                : atFront === 1
                  ? 'atRightBack'
                  : '')
          }
        >
          <img src={imageData[0]} alt='product 'className='fadeInUp_anime' />
          <img src={podium} alt='podium' />
        </div>
      </div>
    );
  };

  
  return (
    <section className='winnerSec pb-0 mpt-0' id="winner">
      <div className='section_title'>
        <h2>Winners 2021</h2>
      </div>
      <div className='winnersInner'>
        {/* <div>
          <div
          onClick={() => set2021(false)}
          className={'card2020 ' + (!year2021 ? 'cardAbove' : 'cardBelow')}
        >
          20
          <br/>
          20
        </div>
          <div
            onClick={() => set2021(true)}
            className={'card2021 ' + (year2021 ? 'cardAbove' : 'cardBelow')}
          >
            20
            <br />
            21
          </div>
        </div> */}
        <div className='winnerSliderContainer'>

          <WinnerProducts frontToken={0} imageData={productImageSet} />
          <WinnerProducts frontToken={1} imageData={productImageSet} />
          <WinnerProducts frontToken={2} imageData={productImageSet} />
          <div className='arrowBottom'>
            <img
              src={leftarrow}
              onClick={() => handleClick(category - 1)}
              alt='arrow'
            />
            <h4>
              {category === 0
                ? 'Product of the year'
                : category === 1
                  ? 'Product launch of the year'
                  : category === 2
                    ? 'Brand of the year'
                    : ''}
            </h4>
            <img
              src={rightarrow}
              onClick={() => handleClick(category + 1)}
              alt='arrow'
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Winnners;
