import React from "react";

const InfoCard = ({ data }) => {
  return (
    <div className="InfoCard_main">
      <div className="title_wall"></div>
      <div className="infoCard_container">
        <div className="infoCard_title">
          <h2>{data?.title}</h2>
        </div>
        <div className="infoCard_description">
          {data?.description?.map((item, index) => {
            if (item.type === "email") {
              return (
                <p key={index}>
                  <a href={`mailto:${item.data}`}> {item.data} </a>
                </p>
              );
            }
            if (item.type === "mobile") {
              return (
                <p key={index}>
                  <a href={`tel:${item.data}`}> {item.data} </a>
                </p>
              );
            }
            return <p key={index}> {item.data}</p>;
          })}
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
