// Importing all the requirements
import React, { Suspense } from "react";
import AddNominations from "../views/AddNomination/AddNominations";
import Login from "../views/Auth/Login/Login";
import SignUp from "../views/Auth/SignUp/SignUp";
import Cart from "../views/Cart/Cart";

import Nominated from "../views/Nominated/Nominated";
// import Register from "../views/Register/Register";
import Profile from "../views/Profile/Profile";
import Home from "../views/Home";
import { Navigate } from "react-router-dom";
import BlankLayout from "../views/BlankLayout";
import AuthLayout from "../views/AuthLayout";
import { useSelector } from "react-redux";
import Questionnaire from "../views/Questionnaire/Questionnaire";
// import Policy from "../views/Policy/Policy";
// import Terms from "../views/Policy/Terms";
import VoteHomePage from "../views/Home/components/VoteHome/VoteHomePage";
import VotePage from "../views/Vote/Vote";
import CompanyVote from "../views/Vote/CompanyVote";
import VoteLogin from "../views/Vote/LoginVote";
import Template from "../views/template/Template";
import WinnersDetails from "../views/Home/components/Winnner2022/WinnerDetails";
import Winnners from "../views/Home/components/WinnersSection/Winners";
import RegisterNew from "../views/Register/Register2023";
import Gallery from "../views/Gallery/Gallery";
import JuryView from "../views/Jury";
import Contact from "../views/contact/Contact";
import PrivacyPolicy from "../views/Policy/PrivacyPolicyAndTerms";
import WhyParticipate from "../views/WhyToParticipate/WhyParticipate";
// import Evaluation_Parameter as EvaluationParameter
import EvaluationParameter from "../views/Evaluation_Parameter/Evaluation_Parameter";
import FaQ from "../views/FaQ/FaQ";
import HowToNominate from "../views/HowtoNominate/HowToNominate";
import AwardCategories from "../views/AwardCategory/awardCategory";
import AllWinners from "../views/Home/components/Winnner2022/AllWinners";
import WinnersCategories from "../views/WinnersCategory/WinnersCategory";
import LiveEvent from "../views/LiveEvent/LiveEvent";
import WinnersCategories2023 from "../views/Home/components/Winners2023/WinnerCategory2023";
import WinnersDetails2023 from "../views/Home/components/Winners2023/WinnerDetails2023";

const Checkout = React.lazy(() => import("../views/Checkout/Checkout"));

// Exporting the Router
export default function Router(isLoggedin) {
  const isLoggedOut = useSelector((state) => state.user.isLoggedOut);

  if (!isLoggedin && isLoggedOut) {
    window.location.href = `https://${window.location.host}`;
  }

  let element = [
    {
      element: isLoggedin ? (
        <AuthLayout />
      ) : isLoggedOut ? (
        <Navigate to="/home" />
      ) : (
        <BlankLayout />
      ),
      path: "/auth",
      children: [
        { path: "register-2023", element: <RegisterNew /> },
        { path: "profile", element: <Profile /> },
        { path: "addnominations", element: <AddNominations /> },
        { path: "cart", element: <Cart /> },
        { path: "nominated", element: <Nominated /> },
        {
          path: "checkout",
          element: (
            <Suspense fallback={<div className="loader">loading ... </div>}>
              {" "}
              <Checkout />
            </Suspense>
          ),
        },
        { path: "questionnaire", element: <Questionnaire /> },
        { path: "template", element: <Template /> },
        { path: "winners", element: <Winnners /> },
        { path: "gallery", element: <Gallery /> },
        { path: "jury", element: <JuryView /> },
        { path: "contact-us", element: <Contact /> },
        { path: "evaluation-parameter", element: <EvaluationParameter /> },
        { path: "faq", element: <FaQ /> },
        { path: "how-to-nominate", element: <HowToNominate /> },
      ],
    },
    {
      element: isLoggedin ? <AuthLayout /> : <BlankLayout />,
      path: "/",
      children: [
        { path: "/", element: <Home /> },
        { path: "login", element: <Login /> },
        { path: "signup", element: <SignUp /> },
        // { path: "register", element: <Register /> },
        { path: "register-2023", element: <RegisterNew /> },
        { path: "policy", element: <PrivacyPolicy /> },
        // { path: "terms", element: <Terms /> },
        { path: "votelogin", element: <VoteLogin /> },
        { path: "voting-category", element: <VoteHomePage /> },
        { path: "votepage/:category", element: <VotePage /> },
        { path: "vote/:name", element: <CompanyVote /> },
        { path: "winnerdetails", element: <WinnersDetails /> },
        { path: "winners", element: <Winnners /> },
        { path: "gallery", element: <Gallery /> },
        { path: "jury", element: <JuryView /> },
        { path: "contact-us", element: <Contact /> },
        { path: "why-participate", element: <WhyParticipate /> },
        { path: "evaluation-parameter", element: <EvaluationParameter /> },
        { path: "faq", element: <FaQ /> },
        { path: "award-category", element: <AwardCategories /> },
        { path: "winner-category", element: <WinnersCategories /> },
        { path: "all-winners", element: <AllWinners /> },
        { path: "how-to-participate", element: <HowToNominate /> },
        { path: "liveevent", element: <LiveEvent/>},
        { path: "winner-category2023", element: <WinnersCategories2023/>},
        { path: "winnerdetails2023", element: <WinnersDetails2023/>},
      ],
    },
  ];

  return element;
}
