import React, { Suspense } from "react";
import { useEffect, useState } from "react";
import "../../assets/css/custom.css";
import HomeModal from "./components/HomeModal/Modal";
// import http from "../../services/HttpClient";
// import bannerVideo from "../../assets/img/post_Event.m4v";
import loader from "../../assets/img/loader.gif";
// import register from "../../assets/img/rightarrow.png";
// import mobileBg from "../../assets/img/webp/mobile_bg.webp";
import user from "../../store/action/user";
import { useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
// import WinnersCategories from "./components/Winnner2022/WinnerCategory";
import Winners2023 from "./components/Winners2023/Winners2023";
import SocialMedia from "./components/SocialMedia/SocialMedia";
import PRComponent from "./components/PR/PR";
import FMCG2023 from "./components/FMCGAward2023/FMCG2023";
import Testimonial2023 from "./components/TestimonialsSection/Testimonial2023";
// import Timeline from "./components/Timeline/Timeline";
import HomeTopSlider from "./components/HomeTopSlider/HomeTopSlider";
import GalleryView from "./components/Gallery";
// import JurySlider2023 from "./components/JurySlider2023/JurySlider2023";

// import homepage components
const PreviousYear = React.lazy(() =>
  import("./components/WinnersSection/previousVideo")
);
const AboutFMCG = React.lazy(() =>
  import("./components/AboutSection/AboutFMCG")
);
const AwardCategory = React.lazy(() =>
  import("./components/AwardsCategory/AwardsCategory")
);
// const Winners = React.lazy(() => import("./components/WinnersSection/Winners"));
const BenefitsSection = React.lazy(() =>
  import("./components/BenefitsSection/Benefits")
);
// const Jury = React.lazy(() => import("./components/JurySection/Jury"));
// const Testimonials = React.lazy(() =>
//   import("./components/TestimonialsSection/Testimonials")
// );
// const Footer = React.lazy(() => import("./components/FooterSection/Footer"));

function Home() {
  // for homepage loader
  const [loading, setLoading] = useState(false);
  const [ipDetail, setIpDetail] = useState(null);
  const dispatch = useDispatch();

  // const [scrolladd, setScroll] = useState(false);
  const [mQuery, setMQuery] = React.useState({
    matches: window.innerWidth > 768 ? true : false,
  });

  useEffect(() => {
    // let mediaQuery = window.matchMedia("(min-width: 768px)");
    window.addEventListener("resize", setMQuery); // this is the cleanup function to remove the listener
    return () => window.removeEventListener("resize", setMQuery);
  }, []);

  // useEffect(() => {
  //   // setLoading(true);
  //   // console.log(mQuery.matches)

  //   // window.onbeforeunload = function () {
  //   //   window.scrollTo(0, 0);
  //   // }

  //   window.addEventListener("scroll", () => {
  //     // console.log( window.scrollY)
  //     // setScroll(window.scrollY > 2000);

  //     if (window.scrollY > (mQuery.matches ? 550 : 1)) {
  //       // setScroll(true);
  //       // console.log(window.scrollY);
  //       // window.scrollTo(0, 0);

  //       // window.scrollY(1500)
  //     } else {
  //       setScroll(false);
  //     }
  //   });
  // }, []);

  let frameNumber = 0; // start video at frame 0
  // lower numbers = faster playback
  let playbackConst = 150;
  let vid = {};

  useEffect(() => {
    const fetchIpDetail = async () => {
      const res = await fetch("https://ipapi.co/json/");
      const data = await res.json();
      setIpDetail(data);
    };

    if (!ipDetail) {
      fetchIpDetail();
    }
  }, [ipDetail]);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params.has("c") && ipDetail) {
      const campaignValue = params.get("c");
      const browserName = getBrowserName();
      dispatch(
        user.websiteClickCapture({
          ...ipDetail,
          value: campaignValue,
          browser: browserName,
        })
      );
    }
  }, [ipDetail]);

  // useEffect(() => {
  //   // get page height from video duration

  //   const saveWebsiteClick = async () => {
  //     const search = window.location.search;
  //     const params = new URLSearchParams(search);
  //     const ipDetail = await getIpDetail();
  //     if (params.has("c")) {
  //       const campaignValue = params.get("c");
  //       ipDetail["value"] = campaignValue;
  //       ipDetail["browser"] = getBrowserName();
  //       dispatch(user.websiteClickCapture(ipDetail));
  //     }
  //   };
  //   saveWebsiteClick();
  //   // let setHeight = document.getElementById("set-height");
  //   // select video element
  //   // vid = document.getElementById("v0");
  //   // setLoading(false);
  //   // console.log(vid);
  //   // dynamically set the page height according to video length

  //   // vid.addEventListener("loadedmetadata", function () {
  //   //   setHeight.style.height = Math.floor(vid.duration) * playbackConst + "px";
  //   //   setLoading(false);
  //   // });
  // }, []);

  // const getIpDetail = async () => {
  //   // return await http.get("https://ipapi.co/json/");
  //   return await fetch("https://ipapi.co/json/").then((res) => res.json());
  // };

  const getBrowserName = () => {
    let nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let verOffset;
    console.log(verOffset);
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
      browserName = "Opera";
    } else if (navigator.userAgent.indexOf("Edg") !== -1) {
      browserName = "Edge";
    } else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      browserName = "Microsoft Internet Explorer";
    } else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      browserName = "Chrome";
    } else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      browserName = "Safari";
    } else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      browserName = "Firefox";
    }

    return browserName;
  };

  // Use requestAnimationFrame for smooth playback
  function scrollPlay() {
    frameNumber = window.pageYOffset / playbackConst;
    vid.currentTime = frameNumber;
    window.requestAnimationFrame(scrollPlay);
  }

  window.requestAnimationFrame(scrollPlay);

  // register now floating button show when user scroll 1250px from top

  useEffect(() => {
    window.addEventListener("scroll", registerShow);
    return () => {
      window.removeEventListener("scroll", registerShow);
    };
  });

  const registerShow = (e) => {
    // const header = document.querySelector(".registerNow");
    // const scrollTop =window.scrollY;
    // scrollTop >= 1250 ? header.classList.add('registerShow') : header.classList.remove('registerShow');
    // console.log(header);
  };

  return (
    <>
      {loading && (
        <div className="loader">
          <img
            src={loader}
            alt="Channelier FMCG Awards"
            width="100"
            height="100"
          />
        </div>
      )}
      <div className="mainContainer">
        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />{" "}
            </div>
          }
        >
          <HomeTopSlider />
        </Suspense>
        {/* <Suspense
            fallback={
              <div className="loader">
                {" "}
                <img src={loader} alt="Channelier FMCG Awards" />{" "}
              </div>
            }
          >
            <section className="videoSec bg-brown">
              <video
                autoPlay={true}
                playsInline
                muted
                loop
                width="100%"
                height="100%"
                className="animate__flipInY"
              >
                <source type="video/mp4" src={bannerVideo}></source>
              </video>
              <div className="RegisterTop">
                <Link to="/login">
                  <button
                    type="button"
                    className="custom_btn brown_btn hvr-shutter-out-vertical"
                  >
                    {" "}
                    Register Now
                  </button>
                </Link>
              </div>
            </section>
          </Suspense> */}

        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />{" "}
            </div>
          }
        >
          <AboutFMCG />
        </Suspense>

        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />{" "}
            </div>
          }
        >
          <FMCG2023 />
        </Suspense>

        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />{" "}
            </div>
          }
        >
          <PreviousYear />
        </Suspense>

        {/* <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />{" "}
            </div>
          }
        >
          <Timeline />
        </Suspense> */}

        {/* 
              <Suspense fallback={ <div className='loader'> <img src={loader} alt='Channelier FMCG Awards'/> </div>}>
                <Winners /> 
              </Suspense>  */}
        {/* <Suspense
            fallback={
              <div className="loader">
                {" "}
                <img src={loader} alt="Channelier FMCG Awards" />{" "}
              </div>
            }
          >
            <WinnersCategories />
          </Suspense> */}
        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />{" "}
            </div>
          }
        >
          <BenefitsSection />
        </Suspense>

        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />{" "}
            </div>
          }
        >
          <AwardCategory />
        </Suspense>

        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />{" "}
            </div>
          }
        >
          <GalleryView />
        </Suspense>

        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />{" "}
            </div>
          }
        >
          <Winners2023 />
        </Suspense>

       
        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />
            </div>
          }
        >
          <PRComponent />
        </Suspense>

        {/* <Suspense
            fallback={
              <div className="loader">
                {" "}
                <img src={loader} alt="Channelier FMCG Awards" />
              </div>
            }
          >
            <JurySlider2023/>
          </Suspense> */}


        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />
            </div>
          }
        >
          <SocialMedia />
        </Suspense>

        <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />
            </div>
          }
        >
          <Testimonial2023 />
        </Suspense>

        {/* <Suspense
          fallback={
            <div className="loader">
              {" "}
              <img src={loader} alt="Channelier FMCG Awards" />{" "}
            </div>
          }
        >
          <Footer />
        </Suspense> */}

        {/* <div className="registerNow">
          <Link to="/login" target="_blank">
            <button type="button" className="custom_btn">
              Register Now
              <div className="arrow_register">
                <img src={register} alt="register" />
              </div>
            </button>
          </Link>
        </div> */}

        <HomeModal />
      </div>
    </>
  );
}

export default Home;
