// Importing the requirements
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import AuthHeader from "../components/header/AuthHeader";
import Sidebar from "../components/sidebar/Sidebar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BgAnimate from "./Home/components/ParticleComponent/Particle";
import Footer from "./Home/components/FooterSection/Footer";

// Auth Layout Function
const AuthLayout = () => {

  const location = useLocation();
  const url = location.pathname;
  const showFooter = !window.location.href.includes('/auth');
  // Authe Header UI
  return (
    
    <div className={!url.includes("/auth") ? "main homepage" : url.includes("winners") ? "winnerpage" : "main"}>
       <BgAnimate/>
      <ToastContainer />
      {url.includes("/auth")  && <Sidebar />}

      <div className="rightsidebar">
        <AuthHeader />
        <Outlet />
        {showFooter && <Footer />}

      </div>

    </div>
  );
};

export default AuthLayout;
