export const GET_AWARD_LIST = "GET_AWARD_LIST";
export const SET_ERROR = "SET_ERROR";
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const SAVE_CART = "SAVE_CART";
export const GET_CART = "GET_CART";
export const UPDATE_CART = "UPDATE_CART";
export const REMOVE_CART = "REMOVE_CART";
export const CLEAR_CART = "CLEAR_CART";
export const SAVE_USER = "SAVE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USER = "GET_USER";
export const SEND_OTP = "SEND_OTP";
export const USER_LOGIN = "USER_LOGIN";
export const VERIFY_OTP = "VERIFY_OTP";
export const RESET_OTP = "RESET_OTP";
export const USER_LOGOUT = "USER_LOGOUT";
export const GET_NOMINATION_LIST = "GET_NOMINATION_LIST";
export const GET_USER_NOMINATION = "GET_USER_NOMINATION";
export const ZERO_PAYMENT = "ZERO_PAYMENT";
export const PAYMENT = "PAYMENT";
export const GET_ORDER = "GET_ORDER";
export const GET_QUESTION = "GET_QUESTION";
export const SAVE_PARTONE_QUESTION = "SAVE_PARTONE_QUESTION";
export const GET_PARTONE_QUESTION = "GET_PARTONE_QUESTION";
export const SAVE_ALL_QUESTION = "SAVE_ALL_QUESTION";
export const GET_ANSWER = "GET_ANSWER";
export const GET_PART_B = "GET_PART_B";
export const CLEAR_ANSWER = "CLEAR_ANSWER";
export const CLEAR_NOMINATION = "CLEAR_NOMINATION";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const GET_JURY = "GET_JURY";
export const GET_COMAPNY_NOMINATION = "GET_COMAPNY_NOMINATION";
export const GET_COMAPNY_DETAIL = "GET_COMAPNY_DETAIL";
export const SAVE_VOTE = "SAVE_VOTE";
export const RESET_VERIFY_OTP = "RESET_VERIFY_OTP";
export const RESET_VOTE = "RESET_VOTE";
//2023
export const CREATE_INTERESTED_USER = "CREATE_INTERESTED_USER";
export const GET_USER_INTEREST = "GET_USER_INTEREST";
