import React  from "react";
import Modal from "react-bootstrap/Modal";

import backArrow from "../../assets/img/arrowBack.png";
import VoteLogin from "./LoginVote";

function LoginVotePopup({show,showHandler,nominationData}) {

  const handleClose = () => showHandler(false);

  return (
    <>
  
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        className="submitVoteModal"
      >
        <div className="container">
          <div className="votemodalBody bg-brown">
            <div className="voteTitle">
              <h4>
                        
                  <img src={backArrow} alt="icon" onClick={()=>handleClose(false)} />
          
                Login to Submit your Vote
              </h4>
            </div>

            <Modal.Body>
              <VoteLogin nominationData={nominationData} showHandler={showHandler} />
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LoginVotePopup;
