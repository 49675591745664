import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const Slider = ({ images, direction }) => {
  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current === null && swiperRef.current === undefined) {
      return;
    }
    const interval = setInterval(() => {
      if (
        swiperRef.current !== null &&
        swiperRef.current !== undefined &&
        direction === "R2L"
      ) {
        swiperRef.current.swiper.slidePrev();
      } else if (
        swiperRef.current !== null &&
        swiperRef.current !== undefined &&
        direction === "L2R"
      ) {
        swiperRef.current.swiper.slideNext();
      }
    }, 0);
    return () => clearInterval(interval);
  });
  return (
    <>
      <Swiper
        ref={swiperRef}
        freeMode={true}
        a11y={true}
        autoplay={{
          delay: 0.5,
          disableOnInteraction: false,
          reverseDirection: true,
        }}
        speed={11000}
        slidesPerView={"auto"}
        loop={true}
        spaceBetween={30}
        className="Gallery_Slide"
        breakpoints={{
          320: {
            spaceBetween: 20,
          },
          480: {
            spaceBetween: 20,
          },
          767: {
            spaceBetween: 30,
          },
          959: {
            spaceBetween: 30,
          },
        }}
      >
        {/* map over the image array to create slides */}
        {images &&
          images.map((image) => {
            return (
              <SwiperSlide key={image.id} className="Gallery_Slide">
                <img className="" src={image.src} alt={image.alt} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  );
};

export default Slider;
