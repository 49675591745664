import React from "react";

const KeyPoint = ({ data, classExtra = "" }) => {
  return (
    <div className="key_point_container">
      <p className={`key_point_number ${classExtra}`}>{data?.number}</p>
      <div className="key_point_details_point">
        <p className="key_point_title text-white">{data?.title}</p>
        {data?.desc && (
          <p className="key_point_desc text-white">{data?.desc}</p>
        )}
      </div>
    </div>
  );
};

export default KeyPoint;
