// import Swiper core and required modules
// import { FiArrowUpRight } from "react-icons/fi";
import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";

// jury slider component
const JurySlider = ({ images, direction }) => {
  // create a ref for swiper instance
  const swiperRef = useRef(null);

  useEffect(() => {
    // set the interval for the slider to autoplay
    // const interval = setInterval(() => {
    //   if (
    //     swiperRef.current !== null &&
    //     swiperRef.current !== undefined &&
    //     direction === "R2L"
    //   ) {
    //     // if direction is R2L then slide to previous image
    //     swiperRef.current.swiper.slidePrev();
    //   } else if (
    //     swiperRef.current !== null &&
    //     swiperRef.current !== undefined &&
    //     direction === "L2R"
    //   ) {
    //     // if direction is L2R then slide to next image
    //     swiperRef.current.swiper.slideNext();
    //   }
    // }, 0);
    // // clear the interval when component unmounts
    // return () => clearInterval(interval);
  });
  return (
    <>
      {/* Swiper Component that have this properties */}
      <Swiper
        ref={swiperRef}
        // freeMode={true}
        // a11y={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: true,
          reverseDirection: direction === "R2L" ? true : false,
        }}
        speed={11000}
        modules={[Autoplay]}
        slidesPerView={"auto"}
        loop={true}
        spaceBetween={30}
        className="Jury_Slide"
        breakpoints={{
          320: {
            spaceBetween: 20,
          },
          480: {
            spaceBetween: 20,
          },
          767: {
            spaceBetween: 30,
          },
          959: {
            spaceBetween: 30,
          },
        }}
      >
        {/* map over the image array to create slides */}
        {images &&
          images.map((image) => {
            return (
              <SwiperSlide key={image.id} className="Jury_Slide">
                <div className="jury_slider_card">
                  {/* company image or logo */}
                  <img
                    className="jury_slider_company"
                    src={image.jury_company_image}
                    alt={image.alt}
                  />

                  {/* image of jury members  */}
                  <img
                    className="jury_slider_main_image"
                    src={image.jury_image_url}
                    alt={image.alt}
                  />

                  {/* jury member name */}
                  <div className="jury_slider_name">
                    <p className="jury_name_text">{image.jury_name}</p>
                    <p className="jury_designation">{image.designation}</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  );
};

export default JurySlider;
