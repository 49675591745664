import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import image1 from "../../assets/img/new/kitchen.png";
import image2 from "../../assets/img/new/bakery.png";
import image3 from "../../assets/img/new/beverages.png";
import image4 from "../../assets/img/new/snacks.png";
import image5 from "../../assets/img/new/beauty.png";
import image6 from "../../assets/img/new/petcare.png";
import image7 from "../../assets/img/new/chicken.png";
import image8 from "../../assets/img/new/babycare.png";
import image9 from "../../assets/img/new/pleasure.png";
import image10 from "../../assets/img/new/cleaning.png";

import AwardCategory from "../Home/components/AwardsCategory/AwardsCategory";
import { useRef } from "react";
import { Autoplay } from "swiper";
import "swiper/css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
// import JudgingCriteria from "./components/JudgingCriteria";
import Evaluation_Parameter from "../Evaluation_Parameter/Evaluation_Parameter";

const AwardCategories = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <>
      <section className="category_page">
        <div className="section_title criteria_title_header">
          <h2>Product categories</h2>
          <div>
              <button className="prv_btn" onClick={handlePrev}>
                <FaArrowLeft />
              </button>
              <button className="nxt_btn" onClick={handleNext}>
                <FaArrowRight />
              </button>
            </div>
        </div>
        <div className="container-fluid">
          <div className="swiper_outer">
            <Swiper
              ref={swiperRef}
              autoplay={{
                delay: 3000,
              }}
              initialSlide={1}
              slidesPerView={1}
              loop={true}
              spaceBetween={20}
              navigation={{
                prevEl: ".my-prev-button",
                nextEl: ".my-next-button",
              }}
              modules={[Autoplay]}
              rewind={true}
              breakpoints={{
                479: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                767: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                959: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },

                1024: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
            >
              <SwiperSlide>
                <div className="categorySlide">
                  <h3>Kitchen Essentials</h3>
                  <img src={image1} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="categorySlide">
                  <h3>Bakery, Cakes & Milk Products</h3>
                  <img src={image2} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="categorySlide">
                  <h3>Beverages</h3>
                  <img src={image3} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="categorySlide">
                  <h3>Snacks & Packaged Foods</h3>
                  <img src={image4} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="categorySlide">
                  <h3>Beauty & Hygiene</h3>
                  <img src={image5} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="categorySlide">
                  <h3>Cleaning & Household</h3>
                  <img src={image10} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="categorySlide">
                  <h3>Pet Care</h3>
                  <img src={image6} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="categorySlide">
                  <h3>Eggs, Meat & Fish</h3>
                  <img src={image7} alt="" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="categorySlide">
                  <h3>Baby Care</h3>
                  <img src={image8} alt="" />
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="categorySlide">
                  <h3>Pleasure Goods</h3>
                  <img src={image9} alt="" />
                </div>
              </SwiperSlide>

            </Swiper>
         
          </div>
        </div>
      </section>

      <section className="guidelines_sec">
        <div className="section_title">
          <h2>Guidelines overview</h2>
        </div>
        <div className="container-fluid">
          <div className="guidelines">
            <ul>
              <li>
                {" "}
                There are no restrictions on the number of entries that a
                company/brand can submit to the Channelier FMCG Awards 2023.{" "}
              </li>
              <li>
                {" "}
                The registration process is entirely online and accessible via
                the website.{" "}
              </li>
              <li>
                {" "}
                To participate, companies must provide the necessary
                information, documents, and materials, as outlined in the
                questionnaire for the relevant categories they have selected.{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>

      <AwardCategory />

      <section className="criteria_sec bg-brown">
        <div className="section_title">
          <h2 className="text-white">Selection Criteria</h2>
        </div>
        <div className="container-fluid">
          <div className="criteria">
            <ul>
              <li>
                The judging process is an impeccable process, completely
                transparent and thoroughly rigorous. It is based on the
                parameters specified for each award category in their evaluation
                parameters.{" "}
              </li>
              <li>
                This process of first shortlisting will be done by the internal
                team of Channelier  based on the research through the product
                reviews and ratings on e-commerce platforms, social media and PR
                coverage, sample evaluation for ensuring basic quality standard,
                survey through calls and whatsapp conducted by the team to name
                a few among the various other processes.{" "}
              </li>
              <li>
                The shortlisted nominated organisations (FMCG brands and their
                products) will be evaluated by an esteemed jury panel composed
                of some reputed leaders of the corporate world from the biggest
                companies in Retail, Investments and Consulting who will present
                their judgement on the basis of the answers of the questionnaire
                filled in by the organisation. {" "}
              </li>
              <li>
                The jury will evaluate the organisations on a scale of 1-10 on
                the attributes mentioned in the questionnaires. The average of
                the marks given by multiple jury members on a product/brand was
                taken as a final score.{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* <JudgingCriteria /> */}
      <Evaluation_Parameter/>
    </>
  );
};

export default AwardCategories;
