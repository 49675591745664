// Importing the requirements
import React, { useEffect, useState } from "react";
import "./assets/css/custom.css";
import Router from "./routes";
import user from "./store/action/user";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// import {run} from 'react-snap'

// Main App Function
function App() {
//  run({
//   include:['/','./views/Vote/']
//  })
  const isLoggedin = useSelector((state) => state.user.isLoggedIn);
  const [isLogged, setIsLogged] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(user.getUser());
  }, []);

  useEffect(() => {
    setIsLogged(isLoggedin);
  }, [isLoggedin]);
  
  const routing = useRoutes(Router(isLogged));

  return <>{routing}</>;
}

export default App;
