// Importing the requirements
import { GET_USER, UPDATE_USER, SET_ERROR,GET_USER_INTEREST, VERIFY_OTP, USER_LOGIN, USER_LOGOUT, RESET_OTP,GET_JURY ,GET_COMAPNY_NOMINATION,GET_COMAPNY_DETAIL, SAVE_VOTE,RESET_VOTE,SEND_OTP} from "../constant/types";

const initialState =  {
  userDetail: [],
  juryDetail:[],
  userProfile: {},
  otpVerified: false,
  errors: {},
  isLoggedIn: false,
  isLoggedOut: false,
  companyDetail:{},
  nominationData:[],
  voteData:{},
  userInterest:{},
  otp:''
};
  
// Use Reducer Function
const UserReducer = (state = initialState, action) => {
  console.log(action?.payload,'payload');
  switch (action.type) {
    
    case UPDATE_USER:
      return {
        ...state,
        userDetail: action.payload?.list,
        errors: {}
      }

    case VERIFY_OTP:
      return {
        ...state,
        otpVerified: action.payload?.otpVerification,
        errors: {}
      }
    case SEND_OTP:
      return {
        ...state,
        otp: action.payload?.list?.otp,
        errors: {}
      }

    case RESET_OTP:
      return {
        ...state,
        otpVerified: action.payload?.otpVerification,
        errors: {}
      }

    case GET_USER:
      return {
        ...state,
        userProfile:action.payload?.list,
        error: {}
      }
    case GET_USER_INTEREST:
      console.log(action.payload,"action.payload,");
      return{
        ...state,
        userInterest:action.payload.list,
        error:{}

      }
 
    case USER_LOGIN:
      return {
        ...state,
        userDetail: action.payload?.list,
        isLoggedIn: true,
        errors: {}
      }

    case USER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        isLoggedOut: true,
        errors: {}
      }
    case GET_JURY:
    return {
      ...state,
      juryDetail:action.payload?.list,
      error: {}
    }
    case GET_COMAPNY_NOMINATION:
    return {
      ...state,
      nominationData:action.payload?.list,
      error: {}
    }
    case GET_COMAPNY_DETAIL:
      return {
        ...state,
        companyDetail:action.payload?.list,
        error: {}
      }
      case SAVE_VOTE:
        return {
          ...state,
          voteData:action.payload?.list,
          error: {}
        }

        case RESET_VOTE:
        return {
          ...state,
          voteData:{},
          error: {}
        }
    


    case SET_ERROR:
        return {
          ...state,
          cartList: [],
          errors: {
            message: action.payload.message
          }
        }
    
    default:
      return state;
    
    }
  }
  
  export default UserReducer;
