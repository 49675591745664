import React from "react";
import KeyPoint from "../../components/KeyPoints/KeyPoint";

const data = [
  {
    number: "1",
    title: "Who can participate in the Channelier FMCG awards?",
    desc: `Any company that produces and sells fast-moving consumer goods is eligible to participate in Channelier FMCG Awards.`,
  },
  {
    number: "2",
    title: "How do I submit a nomination?",
    desc: `To submit a nomination for the Channelier FMCG awards, go to the awards.channelier.com website, register, and then click on the "Add Nomination" tab from the menu bar. Select the award category and product/brand category you want to nominate for, and then fill in the product/brand name.`,
  },
  {
    number: "3",
    title:
      "Is there a fee to submit a nomination for the Channelier FMCG awards?",
    desc: `No, there is no fee to submit a nomination for the Channelier FMCG awards.`,
  },
  {
    number: "4",
    title: "When is the deadline for nominations?",
    desc: `The deadline for nominations varies each year and is usually announced on the awards.channelier.com website.`,
  },
  {
    number: "5",
    title: "Who judges the nominations for the Channelier FMCG awards?",
    desc: `The nominations for the Channelier FMCG awards are judged by a panel of industry experts and professionals. For more details on the jury please refer to the jury section in the website.`,
  },
  {
    number: "6",
    title: "What are the criteria for judging the nominations?",
    desc: `The criteria for judging the nominations include factors such as innovation, market success, customer satisfaction, brand equity, and environmental sustainability. The detailed evaluation parameter for each award category is mentioned in the website.`,
  },
  {
    number: "7",
    title: "How are the winners selected for the Channelier FMCG awards?",
    desc: `The winners of the Channelier FMCG awards are selected based on a combination of shortlisting by the Channelier team, the judges' scores and public voting.`,
  },
  {
    number: "8",
    title: "When and where will the winners be announced?",
    desc: `The winners of the Channelier FMCG awards will be announced at an event held at a prestigious location, which will be announced on the awards.channelier.com website.`,
  },
  {
    number: "9",
    title:
      "What are the benefits of participating in the Channelier FMCG awards?",
    desc: `The benefits of participating in the Channelier FMCG awards include increased brand exposure, industry recognition, networking opportunities, and the potential to increase sales and revenue.`,
  },
  {
    number: "10",
    title:
      "Can I use the Channelier FMCG awards logo or branding in my marketing materials?",
    desc: `Yes, winners and nominees of the Channelier FMCG awards are allowed to use the awards logo and branding in their marketing materials.`,
  },
  {
    number: "11",
    title:
      "How can I contact the organisers of the Channelier FMCG awards for further information?",
    desc: `You can contact the organisers of the Channelier FMCG awards by email or phone, which is usually provided on the awards.channelier.com website.`,
  },
  {
    number: "12",
    title: "What happens if my nomination is not selected for the FMCG Awards?",
    desc: `If your nomination is not selected for the Channelier FMCG awards, you will receive an email informing you of the decision.`,
  },
  {
    number: "13",
    title: "Can I nominate multiple brands/products for the same category?",
    desc: `You can submit as many nominations as you want for the Channelier FMCG awards, as long as they meet the eligibility criteria and fit into one of the six award categories.`,
  },
  {
    number: "14",
    title:
      "Can I nominate a brand/product that has won an award in previous years?",
    desc: `Yes, you can nominate a product that has already won an award in a previous year, as long as it meets the eligibility criteria and fits into one of the six award categories.`,
  },
  {
    number: "15",
    title: "How long has the Channelier FMCG awards been running?",
    desc: `Channelier FMCG awards was started in 2020 and have been running every year since then. The 2020 and 2021 edition were online editions while the 2022 edition was a offline event with the event being held in Goa.`,
  },
  {
    number: "16",
    title: "How do I know if my nomination has been accepted?",
    desc: `You will receive an email confirmation upon successful submission of your nomination. The Channelier team will then review all the nominations and shortlist them based on the eligibility criteria and category requirements.`,
  },
  {
    number: "17",
    title:
      "Will the judges provide feedback on why a nomination was not selected?",
    desc: `You will receive an email confirmation upon successful submission of your nomination. The Channelier team will then review all the nominations and shortlist them based on the eligibility criteria and category requirements.`,
  },
];

const FaQ = () => {
  window.scrollTo(0, 0);
  return (
    <section className="faq_container bg-brown">
      <div className="section_title">
        <h2 className="text-white">FAQ'S</h2>
      </div>
      <div className="container-fluid">
        <div className="faq_container_box">
          {data.map((item, index) => {
            return <KeyPoint data={item} key={index} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default FaQ;
