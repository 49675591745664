import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
// import image1 from "../../../../assets/img/new/banner1.png";
// import image2 from "../../../../assets/img/new/banner2.png";
// import image3 from "../../../../assets/img/new/banner_award.png";

import image1 from "../../../../assets/img/2023/top_slider_banners/new.webp";
import image3 from "../../../../assets/img/2023/top_slider_banners/banner_award.webp";

// styles css component

const HomeTopSlider = () => {
  return (
    <div className="banner_slider bg-brown">
      <Swiper
        freeMode={true}
        a11y={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={900}
        navigation={true}
        modules={[Navigation, Autoplay]}
        slidesPerView={1}
        loop={true}
        spaceBetween={30}
        className="home_top_slide"
        breakpoints={{
          425: {
            slidesPerView: 1,
          },
        }}
      >
        <SwiperSlide className="home_top_slide">
          <img src={image1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="home_top_slide slider_bg">
          <div className="second_banner_slide">
            <img src={image3} alt="" />

            <div className="top_banner_content">
              <h3>
                "Recognizing excellence in FMCG <br /> innovation and
                achievement."
              </h3>
              {/* <p>16 Dec, 2023, New Delhi</p> */}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HomeTopSlider;
