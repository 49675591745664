import { React, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import backArrow from "../../../../assets/img/arrowBack.png";
import _brand from "../../../../assets/json/brand.json";
import _product from "../../../../assets/json/product.json";
import _launch from "../../../../assets/json/launch.json";

function WinnersDetails() {
  const [searchParam, setSearchParam] = useSearchParams();
  const [winnerData, setWinnerData] = useState();
  const [title, setTitle] = useState("");
  let category = searchParam.get("category");

  useEffect(() => {
    if (category === "brand") {
      _brand.map((brand) => {
        brand[
          "image"
        ] = `https://awards.channelier.com/uploads/winner/brand/${brand["S No."]}.png`;
        brand[
          "company_image"
        ] = `https://awards.channelier.com/uploads/winner/brand/${brand["S No."]}.png`;
      });
      setWinnerData(_brand);
      setTitle("Brand of the Year");
    }
    if (category === "product") {
      _product.map((product) => {
        product[
          "image"
        ] = `https://awards.channelier.com/uploads/winner/product/${product["S No."]}.png`;
        product[
          "company_image"
        ] = `https://awards.channelier.com/uploads/winner/product_logo/${product["S No."]}.png`;
      });
      setWinnerData(_product);
      setTitle("Product of the Year");
    }
    if (category === "launch") {
      _launch.map((launch) => {
        launch[
          "image"
        ] = `https://awards.channelier.com/uploads/winner/launch/${launch["S No."]}.png`;
        launch[
          "company_image"
        ] = `https://awards.channelier.com/uploads/winner/launch_logo/${launch["S No."]}.png`;
      });
      setWinnerData(_launch);
      setTitle("Product Launch of the Year");
    }
  }, [category]);

  console.log(winnerData);

  return (
    <section className="bg-brown winnerCategory h-100">
      <div className="container">
        <div className="voteTitle">
          <h4 className="mb-5">
            <Link to="/#2022">
              {" "}
              <img src={backArrow} alt="icon" />{" "}
            </Link>
            {title}
          </h4>
          {/* <p className="text-center text-white mb-5">
            you can check winners here
          </p> */}
        </div>
        <div className="products h-auto">
          {winnerData?.map((data, index) => (
            <div key={index} className="productImg">
              <div className="brandLogoVote">
                {" "}
                <img src={data?.company_image} alt="productLogo" />{" "}
              </div>
              <div className="productImgVote">
                {" "}
                <img src={data?.image} alt="product img" />{" "}
              </div>
              <span>
                {category !== "brand"
                  ? data["Product Name"]
                  : data["Company Name"]}
              </span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WinnersDetails;
