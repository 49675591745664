// Importing the Requirements
import * as React from "react";
import { useState, useEffect } from "react";
import { FloatingLabel, Form, Button, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import storage from "../../services/localStorage";
import user from "../../store/action/user";
import tick from "../../assets/img/tick_icon.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import { Modal } from "react-bootstrap";

// Function to Register for New Company
function RegisterNew() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mobile, setMobile] = useState("");
  const [tandc, setTAndC] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const theme = useTheme();
  const [categoryName, setCategoryName] = React.useState([]);
  const userDetail = useSelector((state) => state.user.userDetail);

  // Use Effect for Getting the current logged in user from local storage
  // useEffect(() => {
  //   // Getting the user Id from local storage
  //   dispatch(
  //     user.getUserInteresUserBytId(
  //       { phone: storage.getUserMobile() },
  //       setUserDetail
  //     )
  //   );
  // }, []);

  useEffect(() => {
    // If the otp send is correct, navigating the user to profile page
    if (userDetail && userDetail?.token && userDetail?.user?.tandc === "on")
      navigate("/auth/profile");
    else {
      if (
        userDetail &&
        userDetail?.token &&
        userDetail?.user?.tandc === "off" &&
        userDetail?.user?.phone !== "" &&
        userDetail?.user?.email !== ""
      )
        navigate("/register-2023");
    }
  }, [userDetail, userDetail?.user?.tandc]);

  const setUserDetail = (data) => {
    if (data) {
      setFullName(data?.name);
      setEmail(data?.email);
      setCompanyName(data?.companyName);
      setMobile(data?.phone);
      setCategoryName(data?.categories);
      data?.tandc === "off" ? setTAndC(false) : setTAndC(true);
      // handleShow();
      setIsButtonDisabled(true);
    }
  };

  // Function to update the Company profile
  const createUser = async () => {
    let formData = {};
    formData["name"] = fullName;
    formData["email"] = email;
    formData["companyName"] = companyName;
    formData["phone"] = storage.getUserMobile();
    formData["tandc"] = "on";
    formData["categories"] = categoryName;
    formData["id"] = storage.getUserId();
    await dispatch(user.createUser(formData));
    toast.success("Registered Successfully Successfully !", {
      autoClose: 1000,
    });
    // navigate to the profile page with refresh to get the updated data
    navigate("/login", { replace: true });
    window.location.reload();
    // dispatch(user.saveInterestedUser(formData, goToConfirm));
  };

  // Function to navigate to the add nomination page
  const goToConfirm = () => {
    handleShow();
  };

  // Setting and Unsetting the Terms and Conditions Flag
  const onTermAndCondition = (flag) => {
    if (flag === "false") {
      setTAndC(true);
    } else {
      setTAndC(false);
    }
  };

  const Required = (string) => {
    return (
      <span>
        {string}
        <span className="required">*</span>
      </span>
    );
  };

  // Function to Handle Submit Action
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) event.stopPropagation();
    else createUser();

    setValidated(true);
  };

  // multi select chip mui

  const names = [
    "Brand of the year",
    "Product of the year",
    "Product launch of the year",
    "Digital initiative of the year",
    "D2C brand of the year",
    "D2C product of the year",
    "Product packaging of the year",
  ];

  function getStyles(name, categoryName, theme) {
    if (categoryName) {
      return {
        fontWeight:
          categoryName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategoryName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // modal open
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const exit = () => dispatch(user.logoutUser());

  // Register UI
  return (
    <>
      <Container>
        <ToastContainer />

        <div className="loginDiv">
          <h2>Register</h2>

          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/* Name */}
            <FloatingLabel
              controlId="floatingInput"
              label={Required("Name")}
              className="mb-4"
            >
              <Form.Control
                required
                type="text"
                placeholder="Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter your Name
              </Form.Control.Feedback>
            </FloatingLabel>

            {/* Name */}
            {/* <FloatingLabel controlId = "floatingInput" label = {Required("Contact")} className = "mb-4">
              <Form.Control required type = "string" placeholder = "Contact" value = {mobile} onChange = {(e) => setMobile(e.target.value)} />
              <Form.Control.Feedback type = "invalid">
                Please Enter your Contact Number
              </Form.Control.Feedback>
            </FloatingLabel> */}

            {/* Email */}
            <FloatingLabel
              controlId="floatingInput"
              label={Required("Email address")}
              className="mb-4"
            >
              <Form.Control
                required
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter your Email
              </Form.Control.Feedback>
            </FloatingLabel>

            {/* Company Name */}
            <FloatingLabel
              controlId="validationCustom03"
              label={Required("Organization name")}
              className="mb-4"
            >
              <Form.Control
                required
                type="text"
                placeholder="Organization name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter your Organization Name
              </Form.Control.Feedback>
            </FloatingLabel>

            {/* category multi select */}

            {/* <FormControl className="mb-4 w-100">
              <InputLabel id="demo-multiple-chip-label" className="multi_label">
                Select Award Categories <span className="text_red">*</span>{" "}
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={categoryName && categoryName.length ? categoryName : []}
                onChange={handleChange}
                className="cstm_multi-slct"
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, categoryName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className="unselct_categry">Click to unselect category</p> */}

            {/* Terms and Conditions */}
            <Form.Group controlId="formFile" className="mb-4 ">
              <input
                required
                value={tandc}
                onChange={(e) => onTermAndCondition(e.target.value)}
                type="checkbox"
                checked={tandc}
              />
              <label>
                &nbsp; I accept the{" "}
                <a href="https://awards.channelier.com/privacy-policy.html">
                  {" "}
                  Privacy Policy{" "}
                </a>
                and{" "}
                <a href="https://awards.channelier.com/terms-use.html">
                  Terms of Use
                </a>
              </label>
              <Form.Control.Feedback type="invalid">
                Please accept Terms and Condition
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              type="submit"
              className="custom_btn brown_btn w-100 mb-2"
              disabled={isButtonDisabled}
            >
              Register
            </Button>
          </Form>
        </div>

        {/* success modal */}
        {/* <Modal className="interest_modal" show={show}>
          <Modal.Header></Modal.Header>

          <Modal.Body>
            <img src={tick} alt="success" />
            <h6 className="mb-3">
              Thank you for showing interest for Channelier FMCG Awards 2023.
              Our team will reach out to you soon
            </h6>
            <Button className="custom_btn brown_btn w-50 mb-2" onClick={exit}>
              Exit
            </Button>
          </Modal.Body>
        </Modal> */}
      </Container>
    </>
  );
}

// Exporting the Register UI
export default RegisterNew;
