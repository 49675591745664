import React from "react";
import { Link } from "react-router-dom";

import image_1 from "../../../../assets/img/2023/gallery/1.webp";
import image_2 from "../../../../assets/img/2023/gallery/2.webp";
import image_3 from "../../../../assets/img/2023/gallery/3.webp";
import image_4 from "../../../../assets/img/2023/gallery/4.webp";
import image_5 from "../../../../assets/img/2023/gallery/5.webp";
import image_6 from "../../../../assets/img/2023/gallery/6.webp";
import image_7 from "../../../../assets/img/2023/gallery/7.webp";
import image_8 from "../../../../assets/img/2023/gallery/8.webp";
import image_9 from "../../../../assets/img/2023/gallery/9.webp";
import image_10 from "../../../../assets/img/2023/gallery/10.webp";
import image_11 from "../../../../assets/img/2023/gallery/11.webp";
import image_12 from "../../../../assets/img/2023/gallery/12.webp";
import image_13 from "../../../../assets/img/2023/gallery/13.webp";
import image_14 from "../../../../assets/img/2023/gallery/14.webp";
import image_15 from "../../../../assets/img/2023/gallery/15.webp";
import image_16 from "../../../../assets/img/2023/gallery/16.webp";
import image_17 from "../../../../assets/img/2023/gallery/17.webp";
import image_18 from "../../../../assets/img/2023/gallery/18.webp";
import image_19 from "../../../../assets/img/2023/gallery/19.webp";
import image_20 from "../../../../assets/img/2023/gallery/20.webp";
import image_21 from "../../../../assets/img/2023/gallery/21.webp";
import image_22 from "../../../../assets/img/2023/gallery/22.webp";
import image_23 from "../../../../assets/img/2023/gallery/23.webp";
import image_24 from "../../../../assets/img/2023/gallery/24.webp";
import image_25 from "../../../../assets/img/2023/gallery/25.webp";
import image_26 from "../../../../assets/img/2023/gallery/26.webp";
import image_27 from "../../../../assets/img/2023/gallery/27.webp";
import image_28 from "../../../../assets/img/2023/gallery/28.webp";
import image_29 from "../../../../assets/img/2023/gallery/29.webp";
import image_30 from "../../../../assets/img/2023/gallery/30.webp";
import image_31 from "../../../../assets/img/2023/gallery/31.webp";
import image_32 from "../../../../assets/img/2023/gallery/32.webp";
import image_33 from "../../../../assets/img/2023/gallery/33.webp";
import image_34 from "../../../../assets/img/2023/gallery/34.webp";
import image_35 from "../../../../assets/img/2023/gallery/35.webp";
import image_36 from "../../../../assets/img/2023/gallery/36.webp";
import image_37 from "../../../../assets/img/2023/gallery/37.webp";
import image_38 from "../../../../assets/img/2023/gallery/38.webp";
import image_39 from "../../../../assets/img/2023/gallery/39.webp";
import image_40 from "../../../../assets/img/2023/gallery/40.webp";

import Slider from "./components/Slider";
import { Nav } from "react-bootstrap";

const images = [
  { id: 1, src: image_1, alt: "image1" },
  { id: 2, src: image_2, alt: "image2" },
  { id: 3, src: image_3, alt: "image3" },
  { id: 4, src: image_4, alt: "image4" },
  { id: 5, src: image_5, alt: "image5" },
  { id: 6, src: image_6, alt: "image6" },
  { id: 7, src: image_7, alt: "image7" },
  { id: 8, src: image_8, alt: "image8" },
  { id: 9, src: image_9, alt: "image9" },
  { id: 10, src: image_10, alt: "image10" },
  { id: 11, src: image_11, alt: "image11" },
  { id: 12, src: image_12, alt: "image12" },
  { id: 13, src: image_13, alt: "image13" },
  { id: 14, src: image_14, alt: "image14" },
  { id: 15, src: image_15, alt: "image15" },
  { id: 16, src: image_16, alt: "image16" },
  { id: 17, src: image_17, alt: "image17" },
  { id: 18, src: image_18, alt: "image18" },
  { id: 19, src: image_19, alt: "image19" },
  { id: 20, src: image_20, alt: "image20" },
  { id: 21, src: image_21, alt: "image21" },
  { id: 22, src: image_22, alt: "image22" },
  { id: 23, src: image_23, alt: "image23" },
  { id: 24, src: image_24, alt: "image24" },
  { id: 25, src: image_25, alt: "image25" },
  { id: 26, src: image_26, alt: "image26" },
  { id: 27, src: image_27, alt: "image27" },
  { id: 28, src: image_28, alt: "image28" },
  { id: 29, src: image_29, alt: "image29" },
  { id: 30, src: image_30, alt: "image30" },
  { id: 31, src: image_31, alt: "image31" },
  { id: 32, src: image_32, alt: "image32" },
  { id: 33, src: image_33, alt: "image33" },
  { id: 34, src: image_34, alt: "image34" },
  { id: 35, src: image_35, alt: "image35" },
  { id: 36, src: image_36, alt: "image36" },
  { id: 37, src: image_37, alt: "image37" },
  { id: 38, src: image_38, alt: "image38" },
  { id: 39, src: image_39, alt: "image39" },
  { id: 40, src: image_40, alt: "image40" },
];

const GalleryView = () => {
  return (
    <section className="gallery_container_page">
      <div className="section_title gallery_title">
        <h2>VISUAL HIGHLIGHTS</h2>
        <Nav.Link href={"/gallery"} className="view_all_link">
          View All
        </Nav.Link>
      </div>
      <div className="gallery_container">
        <Slider direction={"L2R"} images={images.slice(0, 21)} />
        <Slider direction={"R2L"} images={images.slice(21, 41)} />
      </div>
    </section>
  );
};

export default GalleryView;