import React from "react";
import Why from "./components/Why";
import WhatIsForYou from "./components/WhatIsForYou";

const WhyParticipate = () => {
  return (
    <>
      <Why />
      <WhatIsForYou />
    </>
  );
};

export default WhyParticipate;
