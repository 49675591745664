// dependencies import
import React from "react";
import JurySlider from "./components/JurySlider";

//This is the image of jury section
import jury1 from "../../assets/img/jury/webp/1.webp";
import jury2 from "../../assets/img/jury/webp/2.webp";
import jury3 from "../../assets/img/jury/webp/3.webp";
import jury4 from "../../assets/img/jury/webp/4.webp";
import jury5 from "../../assets/img/jury/webp/5.webp";
import jury6 from "../../assets/img/jury/webp/6.webp";
import jury7 from "../../assets/img/jury/webp/7.webp";
import jury8 from "../../assets/img/jury/webp/8.webp";
import jury9 from "../../assets/img/jury/webp/9.webp";
import jury10 from "../../assets/img/jury/webp/10.webp";
import jury11 from "../../assets/img/jury/webp/11.webp";
import jury12 from "../../assets/img/jury/webp/12.webp";
import jury13 from "../../assets/img/jury/webp/13.webp";
import jury14 from "../../assets/img/jury/webp/14.webp";
import jury15 from "../../assets/img/jury/webp/15.webp";
import jury16 from "../../assets/img/jury/webp/16.webp";
import jury17 from "../../assets/img/jury/webp/17.webp";
import jury18 from "../../assets/img/jury/webp/18.webp";
import jury19 from "../../assets/img/jury/webp/19.webp";
import jury20 from "../../assets/img/jury/webp/20.webp";
import jury21 from "../../assets/img/jury/webp/21.webp";
import jury22 from "../../assets/img/jury/webp/22.webp";
import jury23 from "../../assets/img/jury/webp/23.webp";
import jury24 from "../../assets/img/jury/webp/24.webp";

// This is the image of jury's company
import company1 from "../../assets/img/jury_logo/webp/1.webp";
import company2 from "../../assets/img/jury_logo/webp/2.webp";
import company3 from "../../assets/img/jury_logo/webp/3.webp";
import company4 from "../../assets/img/jury_logo/webp/4.webp";
import company5 from "../../assets/img/jury_logo/webp/5.webp";
import company6 from "../../assets/img/jury_logo/webp/6.webp";
import company7 from "../../assets/img/jury_logo/webp/7.webp";
import company8 from "../../assets/img/jury_logo/webp/8.webp";
import company9 from "../../assets/img/jury_logo/webp/9.webp";
import company10 from "../../assets/img/jury_logo/webp/10.webp";
import company11 from "../../assets/img/jury_logo/webp/11.webp";
import company12 from "../../assets/img/jury_logo/webp/12.webp";
import company13 from "../../assets/img/jury_logo/webp/13.webp";
import company14 from "../../assets/img/jury_logo/webp/14.webp";
import company15 from "../../assets/img/jury_logo/webp/15.webp";
import company16 from "../../assets/img/jury_logo/webp/16.webp";
import company17 from "../../assets/img/jury_logo/webp/17.webp";
import company18 from "../../assets/img/jury_logo/webp/18.webp";
import company19 from "../../assets/img/jury_logo/webp/19.webp";
import company20 from "../../assets/img/jury_logo/webp/20.webp";
import company21 from "../../assets/img/jury_logo/webp/21.webp";
import company22 from "../../assets/img/jury_logo/webp/22.webp";
import company23 from "../../assets/img/jury_logo/webp/23.webp";
import company24 from "../../assets/img/jury_logo/webp/24.webp";

// This is the dummy data for jury members
const data = [
  {
    id: 1,
    jury_name: "Sameer Shaikh",
    designation: "AGM - Regional Head : Buying & Merchandising",
    jury_image_url: jury1,
    jury_company_image: company1,
  },
  {
    id: 2,
    jury_name: "Vikas Iyer",
    designation: "Head of Marketing",
    jury_image_url: jury2,
    jury_company_image: company2,
  },
  {
    id: 3,
    jury_name: "Priyanka Bhargav",
    designation: "Senior Director - Marketing & Research Head",
    jury_image_url: jury3,
    jury_company_image: company3,
  },
  {
    id: 4,
    jury_name: "Kanchan Mishra",
    designation: "Senior Director-FMCG",
    jury_image_url: jury4,
    jury_company_image: company4,
  },
  {
    id: 5,
    jury_name: "Tapojoy Chatterjee",
    designation: "Head - Amazon Smart Commerce",
    jury_image_url: jury5,
    jury_company_image: company5,
  },
  {
    id: 6,
    jury_name: "Sourabh Jeswani",
    designation: "Product Leader- Amazon Pharmacy",
    jury_image_url: jury6,
    jury_company_image: company6,
  },
  {
    id: 7,
    jury_name: "Mayank Jain",
    designation: "AVP-Product Marketing",
    jury_image_url: jury7,
    jury_company_image: company7,
  },
  {
    id: 8,
    jury_name: "Ratul Ghosh",
    designation: "Chief Growth & Marketing Officer",
    jury_image_url: jury8,
    jury_company_image: company8,
  },
  {
    id: 9,
    jury_name: "Ram Kumar",
    designation: "Sr Director & Head, Data Sciences",
    jury_image_url: jury9,
    jury_company_image: company9,
  },
  {
    id: 10,
    jury_name: "Raj Rishi Singh",
    designation: "Chief Business Officer",
    jury_image_url: jury10,
    jury_company_image: company10,
  },
  {
    id: 11,
    jury_name: "Jagnoor Singh",
    designation: "Chief Business Officer",
    jury_image_url: jury11,
    jury_company_image: company11,
  },
  {
    id: 12,
    jury_name: "Abhiram Sripathy",
    designation: "Director",
    jury_image_url: jury12,
    jury_company_image: company12,
  },
  {
    id: 13,
    jury_name: "Prashant Sukhwani",
    designation: "Vice President - Marketing",
    jury_image_url: jury13,
    jury_company_image: company13,
  },
  {
    id: 14,
    jury_name: "Suhail Khan",
    designation: "Senior Director - Business Operations",
    jury_image_url: jury14,
    jury_company_image: company14,
  },
  {
    id: 15,
    jury_name: "Sameer Nagpal",
    designation: "Director - Head of Servicing Strategy & Operations",
    jury_image_url: jury15,
    jury_company_image: company15,
  },
  {
    id: 16,
    jury_name: "Vaibhav Mehrotra",
    designation: "Head of Marketing",
    jury_image_url: jury16,
    jury_company_image: company16,
  },
  {
    id: 17,
    jury_name: "Amit Verma",
    designation: "VP- Head Of Marketing & Growth",
    jury_image_url: jury17,
    jury_company_image: company17,
  },
  {
    id: 18,
    jury_name: "Aakash Kushwaha",
    designation: "Senior Director, Founder office- Growth & New Initiatives",
    jury_image_url: jury18,
    jury_company_image: company18,
  },
  {
    id: 19,
    jury_name: "Praveen Dwivedi",
    designation: "Country Head & COO",
    jury_image_url: jury19,
    jury_company_image: company19,
  },
  {
    id: 20,
    jury_name: "Sambit Swain",
    designation: "Head of Operations - Warehousing & Distribution",
    jury_image_url: jury20,
    jury_company_image: company20,
  },
  {
    id: 21,
    jury_name: "Mona Cheriyan",
    designation: "E.A. President & Group Head Human Resources",
    jury_image_url: jury21,
    jury_company_image: company21,
  },
  {
    id: 22,
    jury_name: "Priyanka Sinha",
    designation: "Global Digital Programs Lead",
    jury_image_url: jury22,
    jury_company_image: company22,
  },
  {
    id: 23,
    jury_name: "Avantika Rajagopalan",
    designation: "VP Finance",
    jury_image_url: jury23,
    jury_company_image: company23,
  },
  {
    id: 24,
    jury_name: "Ujjawal Singh",
    designation: "Head of Innovation and Interoperability",
    jury_image_url: jury24,
    jury_company_image: company24,
  },
];

// jury page component
const JuryView = () => {
  return (
    // jury page container
    <section className="gallery_container_page">
      <div className="section_title">
        <h2>Jury</h2>
      </div>

      {/* Jury slider contaners */}
      <div className="gallery_container">
        <JurySlider direction={"L2R"} images={data.slice(0, 12)} />
        <JurySlider direction={"R2L"} images={data.slice(12, 24)} />
      </div>
    </section>
  );
};

export default JuryView;
