import { React } from 'react';
import { Link } from 'react-router-dom';
import category1 from '../../../../assets/img/2023/seal2023/product_launch_of the_year.png';
import category2 from '../../../../assets/img/2023/seal2023/brand_of_the_year.png';
import category3 from '../../../../assets/img/2023/seal2023/product_of_the_year.png';
import category4 from '../../../../assets/img/2023/seal2023/digital_initiative_of_the_year.png';
import category5 from '../../../../assets/img/2023/seal2023/d2c_brand_of_the_year.png';
import category6 from '../../../../assets/img/2023/seal2023/d2c_product_of_the_year.png';



function WinnersCategories2023() {

  return (
   <section className='winnerCategory' id='2022'>
     <div className='container'>
       <div className='section_title ps-0'>
          <h2 >Winners 2023</h2>
        </div>

      <div className='winnerRow'>
          <div className='winnerColumn'>
            <Link to='/winnerdetails2023?category=launch'>
                  <img src={category1} alt='img'/>
                  <h4>Product Launch of the year</h4>
            </Link>
          </div>

        

        <div className='winnerColumn'>
          <Link to='/winnerdetails2023?category=brand'>
              <img src={category2} alt='img'/>
              <h4>Brand of the year</h4>
              </Link>
        </div>
        
        <div className='winnerColumn'>
           <Link to='/winnerdetails2023?category=product'>
              <img src={category3} alt='img'/>
              <h4>Product of the year</h4>
            </Link>
        </div>

        



    </div>

    <div className='winnerRow'>
          <div className='winnerColumn'>
            <Link to='/winnerdetails2023?category=digital'>
                  <img src={category4} alt='img'/>
                  <h4>Digital initiative of the year</h4>
            </Link>
          </div>

        
        <div className='winnerColumn'>
          <Link to='/winnerdetails2023?category=d2cbrand'>
              <img src={category5} alt='img'/>
              <h4>D2C Brand of the year</h4>
              </Link>
        </div>
        
        <div className='winnerColumn'>
           <Link to='/winnerdetails2023?category=d2cproduct'>
              <img src={category6} alt='img'/>
              <h4>D2C Product of the year</h4>
            </Link>
        </div>
    </div>

     </div>
   </section>
    
  )
}

export default WinnersCategories2023;