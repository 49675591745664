import React from "react";

import posterThumbnail from "../../../../assets/img/2023/award_trailer_2023_tumb.webp";

const FMCG2023 = () => {
  return (
    <section className="bg-brown award2023" id="pr_home">
      <div className="section_title">
        <h2 className="text-white">FMCG AWARDS 2023</h2>
      </div>
      <div className="container-fluid">
        <video
          className="w-100"
          loop={true}
          autoPlay={false}
          controls={true}
          poster={posterThumbnail}
        >
          <source
            type="video/mp4"
            src={require("../../../../assets/img/2023/Awards_Trailer.mp4")}
          ></source>
        </video>
      </div>
    </section>
  );
};

export default FMCG2023;
