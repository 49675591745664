import React from "react";

import image_1 from "../../../../assets/img/2023/winners/1.webp";
import image_2 from "../../../../assets/img/2023/winners/2.webp";
import image_3 from "../../../../assets/img/2023/winners/3.webp";
import image_4 from "../../../../assets/img/2023/winners/4.webp";
import image_5 from "../../../../assets/img/2023/winners/5.webp";
import image_6 from "../../../../assets/img/2023/winners/6.webp";
import image_7 from "../../../../assets/img/2023/winners/7.webp";
import image_8 from "../../../../assets/img/2023/winners/8.webp";
import image_9 from "../../../../assets/img/2023/winners/9.webp";
import image_10 from "../../../../assets/img/2023/winners/10.webp";
import image_11 from "../../../../assets/img/2023/winners/11.webp";
import image_12 from "../../../../assets/img/2023/winners/12.webp";
import image_13 from "../../../../assets/img/2023/winners/13.webp";
import image_14 from "../../../../assets/img/2023/winners/14.webp";
import image_15 from "../../../../assets/img/2023/winners/15.webp";
import image_16 from "../../../../assets/img/2023/winners/16.webp";
import image_17 from "../../../../assets/img/2023/winners/17.webp";
import image_18 from "../../../../assets/img/2023/winners/18.webp";
import image_19 from "../../../../assets/img/2023/winners/19.webp";
import image_20 from "../../../../assets/img/2023/winners/20.webp";
import image_21 from "../../../../assets/img/2023/winners/21.webp";
import image_22 from "../../../../assets/img/2023/winners/22.webp";
import image_23 from "../../../../assets/img/2023/winners/23.webp";
import image_24 from "../../../../assets/img/2023/winners/24.webp";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

const images = [
  {
    id: 1,
    src: image_1,
    alt: "image1",
  },
  {
    id: 2,
    src: image_2,
    alt: "image2",
  },
  {
    id: 3,
    src: image_3,
    alt: "image3",
  },
  {
    id: 4,
    src: image_4,
    alt: "image4",
  },
  {
    id: 5,
    src: image_5,
    alt: "image5",
  },
  {
    id: 6,
    src: image_6,
    alt: "image6",
  },
  {
    id: 7,
    src: image_7,
    alt: "image7",
  },
  {
    id: 8,
    src: image_8,
    alt: "image8",
  },
  {
    id: 9,
    src: image_9,
    alt: "image9",
  },
  {
    id: 10,
    src: image_10,
    alt: "image10",
  },
  {
    id: 11,
    src: image_11,
    alt: "image11",
  },
  {
    id: 12,
    src: image_12,
    alt: "image12",
  },
  {
    id: 13,
    src: image_13,
    alt: "image13",
  },
  {
    id: 14,
    src: image_14,
    alt: "image14",
  },
  {
    id: 15,
    src: image_15,
    alt: "image15",
  },
  {
    id: 16,
    src: image_16,
    alt: "image16",
  },
  {
    id: 17,
    src: image_17,
    alt: "image17",
  },
  {
    id: 18,
    src: image_18,
    alt: "image18",
  },
  {
    id: 19,
    src: image_19,
    alt: "image19",
  },
  {
    id: 20,
    src: image_20,
    alt: "image20",
  },
  {
    id: 21,
    src: image_21,
    alt: "image21",
  },
  {
    id: 22,
    src: image_22,
    alt: "image22",
  },
  {
    id: 23,
    src: image_23,
    alt: "image23",
  },
  {
    id: 24,
    src: image_24,
    alt: "image24",
  },
];

const Winners2023 = () => {
  return (
    <section className="winners2023_section bg-brown" id="winners2023_home">
      <div className="section_title">
        <h2 className="text-white">WINNERS 2022</h2>
      </div>
      <div className="sub_header_winners">
        <div></div>
        {/* <p className="text-white">Some of our Winners This year</p> */}
        <Nav.Link href={"/winner-category"} className="view_all_link">
          Check All Winners
        </Nav.Link>
      </div>
      <div className="container-fluid">
        <div className="winners2023_container">
          {images.map((image) => {
            return (
              <div key={image.id} className="winners2023_image_container">
                <img
                  className="winners2023_image"
                  src={image.src}
                  alt={image.alt}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Winners2023;
