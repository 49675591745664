// Importing the requirements
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

// Rendering the App
//new isssue 
// ReactDOM.render(
//   <Provider store = { store } >
//     <BrowserRouter >
//       <App />
//     </BrowserRouter>
//   </Provider>,
//   document.getElementById('root')
// );


const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate( 
     <Provider store = { store } >
      <BrowserRouter >
        <App />
      </BrowserRouter>
    </Provider>, rootElement);
} else {
  ReactDOM.render(  
  <Provider store = { store } >
    <BrowserRouter >
      <App />
    </BrowserRouter>
  </Provider>, rootElement);
}

reportWebVitals();
