import React from "react";
import KeyPoint from "../../components/KeyPoints/KeyPoint";

const data = [
  {
    number: "A",
    title: `Visit awards.channelier.com and click on the "Register Now" button.`,
  },
  {
    number: "B",
    title: `Enter your mobile number and the OTP received on it. If you are a new user, provide your registration details such as your name, company name, company website, and email address.`,
  },
  {
    number: "C",
    title: `If you are already a registered user, you will be redirected to your user dashboard where you can view all your previously-filled profile details.`,
  },
  {
    number: "D",
    title: `To add a nomination, select the "Add Nomination" tab from the menu bar.`,
  },
  {
    number: "E",
    title: `Choose the award category from the dropdown list of six categories.`,
  },
  {
    number: "F",
    title: `Select the product/brand category you wish to nominate and provide the brand or product name.`,
  },
  {
    number: "G",
    title: `Click on the "Add to Nomination" option.`,
  },
  {
    number: "H",
    title: `You can add multiple nominations by repeating the aforementioned steps.`,
  },
  {
    number: "I",
    title: `To view your nominated categories of products/brands, navigate to the "Nominated" tab on the website.`,
  },
];

const HowToNominate = () => {
  return (
    <section className="how_to_nominate_sec bg-brown">
      <div className="section_title">
        <h2 className="text-white">Guidelines overview</h2>
      </div>
      <div className="container-fluid">
        <ul className="how_to_nominate_list">
          <li>
            There are no restrictions on the number of entries that a
            company/brand can submit to the Channelier FMCG Awards 2023.
          </li>
          <li>
            The registration process is entirely online and accessible via the
            website.
          </li>
          <li>
            To participate, companies must provide the necessary information,
            documents, and materials, as outlined in the questionnaire for the
            relevant categories they have selected.
          </li>
        </ul>
      </div>

      <div className="section_title">
        <h2 className="text-white">How? (Steps to nominate)</h2>
      </div>

      <div className="container-fluid">
        <div className="how_to_container_box">
          <div className="how_to_container_box_elements">
            {" "}
            {data.slice(0, data.length - 1).map((item, index) => {
              return <KeyPoint data={item} key={index} />;
            })}
            <KeyPoint
              data={data[data.length - 1]}
              classExtra={"last_process_element"}
            />
            ;
          </div>
          <p className="text-white">
            * Following these steps will ensure that your brand or product
            receives the recognition it deserves at the upcoming FMCG awards
            event.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowToNominate;
