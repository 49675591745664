import { React, useState, useEffect } from 'react';

import about from '../../../../assets/img/votingImg.png';
import prod1 from '../../../../assets/img/prod1.png';
import { Link } from 'react-router-dom';
import user from '../../../../store/action/user';
import { useSelector, useDispatch } from 'react-redux';
import "swiper/css";



// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation, Autoplay } from 'swiper';


function VoteHomePage() {


  const nominationdata = useSelector(state => state.user.nominationData)
  const startDate = "2021-12-31T18:30:00.000Z";
  const endDate = "2022-12-30T18:30:00.000Z";
  const dispatch = useDispatch()

  let productoFTheYear = nominationdata.filter((product) => product.awardCategory === "5eaa9dd55aebf3609433697d")
  let brandOfTheYear = nominationdata.filter((product) => product.awardCategory === "5eaa9ed95aebf36094336982")
  let launchOfTheYear = nominationdata.filter((product) => product.awardCategory === "5eaa9ea15aebf36094336981")


  useEffect(() => {
    dispatch(user.getCompanyNomination({ startDate, endDate }))
  }, [])
  /* Enter here your End date in this format! */
  let endDate1 = "dec 16, 2022 00:00:00";

  /* ***** Do not change this code below. ***** */
  let deadline = new Date(endDate1).getTime();

  let x = setInterval(function () {
    let now = new Date().getTime();
    let t = deadline - now;
    let days = Math.floor(t / (1000 * 60 * 60 * 24));
    let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    //   var seconds = Math.floor((t % (1000 * 60)) / 1000);
    days=String(days)
    hours=String(hours)
    minutes=String(minutes);
    if (days<10) {
      days="0"+days
    }
    if (hours<10) {
      hours="0"+hours
    }
    if (minutes<10) {
      minutes="0"+minutes
    }

    

      document.getElementById("day1").innerHTML = days[0];
      document.getElementById("day2").innerHTML = days[1];
  
      document.getElementById("hour1").innerHTML = hours[0];
      document.getElementById("hour2").innerHTML = hours[1];
  
      document.getElementById("minute1").innerHTML = minutes[0];
      document.getElementById("minute2").innerHTML = minutes[1];

   

    //   document.getElementById("second").innerHTML = seconds;

    /* Output the End date. (Only for Demo) */
    // document.getElementById("deadline").innerHTML = endDate1;

    if (t < 0) {
      clearInterval(x);
      document.getElementById("demo").innerHTML = "COUNTDOWN FINISHED";
      document.getElementById("day1").innerHTML = "0";
      document.getElementById("day2").innerHTML = "0";
      document.getElementById("hour1").innerHTML = "0";
      document.getElementById("hour2").innerHTML = "0";
      document.getElementById("minute1").innerHTML = "0";

      document.getElementById("minute2").innerHTML = "0";
      // document.getElementById("second").innerHTML = "0";
    }
  }, 1000);
  
  const getImageSource = (imageLink) => {
    if (imageLink) {
      const imageLinkChunks = imageLink.split('/');

      return `https://awards.channelier.com/uploads/${imageLinkChunks[imageLinkChunks.length - 1]}`
    }
  }


  return (

    <div className='countdown bg-brown'>


      <div className="container">

        <h4>Voting Closes in</h4>
        <div id="clockdiv">

          <div>
            <span className="days" id="day1"></span>
            <span className="days" id="day2"></span>
            <div className="smalltext">Days</div>
          </div>

          <div>
          <span className="hours" id="hour1"></span>
            <span className="hours" id="hour2"></span>
            <div className="smalltext">Hours</div>
          </div>

          <div>
            <span className="minutes" id="minute1"></span>
            <span className="minutes" id="minute2"></span>
            <div className="smalltext">Minutes</div>
          </div>
          {/* <div>
                        <span className="seconds" id="second"></span>
                        <div className="smalltext">Seconds</div>
                    </div> */}
        </div>

        <div className='votingImg text-center'>
          <img src={about} alt='' />
          <p className='text-white'>Channelier FMCG Awards is the world’s biggest FMCG award honoring FMCG brands and products. The annual Channelier FMCG Awards recognizes and celebrates the success of FMCG companies across India.
            It provides a unique opportunity for FMCG brands to get recognized and showcase themselves and their products to the Indian market.</p>
        </div>
        <div className='votingSlider'>
          <h3>Brand of the Year <Link to='/votepage/brand'>View all nomination</Link></h3>

          <Swiper
            effect='slide'
            autoplay={{
              delay: 2000
            }}
            initialSlide={1}
            loop={true}
            slidesPerView={4}
            navigation={true}
            modules={[Navigation, Autoplay]}
            spaceBetween={50}
            className="VotingSlide"
             // Responsive breakpoints
             breakpoints= {{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              // when window width is >= 767px
              767: {
                slidesPerView: 3,
                spaceBetween: 50
              },

               // when window width is >= 959px
               959: {
                slidesPerView: 4,
                spaceBetween: 50
              }
            }}
          >
            {brandOfTheYear?.map((brand, index) =>
              <SwiperSlide className='votingSlide' key={index}>
                 {brand?.userProfile.length &&typeof(brand?.userProfile[0]?.productImage)!=="string" && brand?.userProfile[0]?.productImage?.image!=="" &&  brand?.userProfile[0]?.productImage?.image!=="null"?
                                <img src={getImageSource(brand?.userProfile[0]?.productImage?.image)} alt='voting' className='votingImage'  />
                            :<p>{brand?.productName}</p>}
              </SwiperSlide>
            )}

          </Swiper>


        </div>

        <div className='votingSlider'>
          <h3>Product of the Year <Link to='/votepage/product'>View all nomination</Link></h3>

          <Swiper
            effect='slide'
            autoplay={{
              delay: 2500
            }}
            initialSlide={1}
            loop={true}
            slidesPerView={4}
            navigation={true}
            modules={[Navigation, Autoplay]}
            spaceBetween={50}
            className="VotingSlide"
            // Responsive breakpoints
            breakpoints= {{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              // when window width is >= 767px
              767: {
                slidesPerView: 3,
                spaceBetween: 50
              },

               // when window width is >= 959px
               959: {
                slidesPerView: 4,
                spaceBetween: 50
              }
            }}
          >
            {productoFTheYear?.map((product, index) =>
              <SwiperSlide className='votingSlide' key={index}>
                 {product?.userProfile.length && typeof(product?.userProfile[0]?.productImage)!=="string" &&product?.userProfile[0]?.productImage?.image!==""&&product?.userProfile[0]?.productImage?.image!=="null"?
                                <img src={getImageSource(product?.userProfile[0]?.productImage?.image)} alt='voting' className='votingImage'  />
                            :<p>{product?.productName}</p>}
              </SwiperSlide>
            )}

          </Swiper>


        </div>


        <div className='votingSlider'>
          <h3>Product Launch of the Year <Link to='/votepage/launch'>View all nomination</Link></h3>

          <Swiper
            effect='slide'
            autoplay={{
              delay: 3000
            }}
            initialSlide={1}
            loop={true}
            slidesPerView={4}
            navigation={true}
            modules={[Navigation, Autoplay]}
            spaceBetween={50}
            className="VotingSlide"
            // Responsive breakpoints
            breakpoints= {{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              // when window width is >= 767px
              767: {
                slidesPerView: 3,
                spaceBetween: 50
              },

               // when window width is >= 959px
               959: {
                slidesPerView: 4,
                spaceBetween: 50
              }
            }}

          >

            {launchOfTheYear?.map((launch, index) =>
              <SwiperSlide className='votingSlide' key={index}>
                {launch?.userProfile.length && typeof(launch?.userProfile[0]?.productImage)!=="string" && launch?.userProfile[0]?.productImage?.image!==""&&launch?.userProfile[0]?.productImage?.image!=="null"&&launch?.userProfile[0]?.productImage?.image!==null?
                                <img src={getImageSource(launch?.userProfile[0]?.productImage?.image)} alt='voting' className='votingImage'  />
                            :<p>{launch?.productName}</p>}
              </SwiperSlide>
            )}

          </Swiper>


        </div>

      </div>
    </div>
  )
}

export default VoteHomePage