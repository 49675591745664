import React from "react";

// import images
import image from "../../../../assets/img/2023/SocialMedia/1.webp";

const SocialMedia = () => {
  return (
    <section className="social_media_home bg-brown" id="social_media_home">
      <div className="section_title">
        <h2 className="text-white">Social Buzz</h2>
      </div>
      <div className="container-fluid">
        <div className="social_media_image_container">
          <img src={image} alt="social media" className="social_media_image" />
        </div>
      </div>
    </section>
  );
};

export default SocialMedia;
