import React from "react";
import InfoCard from "../../components/InfoCard/InfoCard";

const data = [
  {
    title: "Information Collection -",
    description: [
      {
        data: "We may collect personal information such as your name, company name, email address, phone number, and other details when you register, submit nominations, participate in voting, or attend the awards event. We may also collect non-personal information such as your IP address, browser type, and operating system for analytics and marketing purposes.",
        fontSize: "14px",
        type: "text",
      },
    ],
  },
  {
    title: "Information Use - ",
    description: [
      {
        data: "We use your personal information to process your registration, nominations, votes, and attendance at the awards event, as well as to communicate with you about the awards and related events. We may also use your information for marketing and promotional purposes, such as sending newsletters, surveys, or special offers, if you have opted in to receive them.",
        fontSize: "14px",
        type: "text",
      },
    ],
  },
  {
    title: "Information Disclosure -",
    description: [
      {
        data: "We do not share, sell, rent, or disclose your personal information to third parties without your consent, except for the purposes of the Channelier FMCG Awards' and promotion. We may share your information with our partners, sponsors, judges, vendors, or service providers who need to access it for the awards' administration and delivery. We may also disclose your information if required by law, court order, or government agency.",
        fontSize: "14px",
        type: "text",
      },
    ],
  },
  {
    title: "Information Protection -",
    description: [
      {
        data: "We use reasonable security measures to protect your personal information from unauthorised access, use, or disclosure. However, no system can guarantee 100% security, and we cannot be responsible for any breaches or damages caused by unauthorised access or use of your information.",
        fontSize: "14px",
        type: "text",
      },
    ],
  },
  {
    title: "Information Retention -",
    description: [
      {
        data: "We retain your personal information for as long as necessary to fulfil the purposes for which it was collected, or as required by law. We may also retain your information for statistical, historical, or research purposes, in a form that does not identify you personally.",
        fontSize: "14px",
        type: "text",
      },
    ],
  },
  {
    title: "Information Choices -",
    description: [
      {
        data: "You have the right to access, modify, or delete your personal information, or to object to its processing or use for marketing purposes, by contacting us at priyanka@channelier.com or rohit@channelier.com. You can also opt out of receiving marketing emails or newsletters by following the unsubscribe link at the bottom of each email. Please note that even if you opt out, we may still send you transactional emails related to the awards.",
        fontSize: "14px",
        type: "text",
      },
    ],
  },
  {
    title: "Information Updates -",
    description: [
      {
        data: "We may update this privacy policy from time to time, by posting the revised version on our website. We encourage you to review this policy periodically to stay informed about our information practices.",
        fontSize: "14px",
        type: "text",
      },
    ],
  },
  {
    title: "Contact Information -",
    description: [
      {
        data: "If you have any questions, concerns, or complaints about this privacy policy or our information practices, please contact us at",
        fontSize: "14px",
        type: "text",
        flow: "inline",
      },
      {
        data: "priyanka@channelier.com",
        fontSize: "14px",
        type: "email",
        color: "#F7B824",
        flow: "inline",
      },
      {
        data: "or",
        fontSize: "14px",
        type: "text",
        flow: "inline",
      },
      {
        data: "rohit@channelier.com",
        fontSize: "14px",
        type: "email",
        color: "#F7B824",
        flow: "inline",
      },
    ],
  },
];

const PrivacyPolicy = () => {
  window.scrollTo(0, 0);

  return (
    <section className="policy_container bg-brown">
      <div className="section_title">
        <h2 className="text-white">T&C and privacy policy</h2>
      </div>
      <div className="container-fluid">
        <div className="policy_container_box">
          {data.map((item, index) => {
            return <InfoCard data={item} key={index} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
