import React from "react";
import InfoCard from "../../components/InfoCard/InfoCard";

const data = [
  {
    title: "For nominations -",
    description: [
      {
        data: "Rohit Gupta",
        fontSize: "24px",
        type: "text",
      },
      {
        data: "rohit@channelier.com",
        fontSize: "24px",
        type: "email",
      },
      {
        data: "+91-8882957186",
        fontSize: "24px",
        type: "mobile",
      },
    ],
  },
  {
    title: "For queries -",
    description: [
      {
        data: "Priyanka Das",
        fontSize: "24px",
        type: "text",
      },
      {
        data: "priyanka@channelier.com",
        fontSize: "24px",
        type: "email",
      },
      {
        data: "+91-7973794384",
        fontSize: "24px",
        type: "mobile",
      },
    ],
  },
];

const Contact = () => {
  return (
    <section className="contact_container bg-black" id="contact_us">
      <div className="section_title">
        <h2 className="text-white">CONTACT US</h2>
      </div>
      <div className="container-fluid">
        <div className="glass_blur_container contact_container_box">
          {data.map((item, index) => {
            return <InfoCard data={item} key={index} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Contact;
