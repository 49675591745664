import React from "react";

import image_1 from "../../assets/img/2023/gallery/1.webp";
import image_2 from "../../assets/img/2023/gallery/2.webp";
import image_3 from "../../assets/img/2023/gallery/3.webp";
import image_4 from "../../assets/img/2023/gallery/4.webp";
import image_5 from "../../assets/img/2023/gallery/5.webp";
import image_6 from "../../assets/img/2023/gallery/6.webp";
import image_7 from "../../assets/img/2023/gallery/7.webp";
import image_8 from "../../assets/img/2023/gallery/8.webp";
import image_9 from "../../assets/img/2023/gallery/9.webp";
import image_10 from "../../assets/img/2023/gallery/10.webp";
import image_11 from "../../assets/img/2023/gallery/11.webp";
import image_12 from "../../assets/img/2023/gallery/12.webp";
import image_13 from "../../assets/img/2023/gallery/13.webp";
import image_14 from "../../assets/img/2023/gallery/14.webp";
import image_15 from "../../assets/img/2023/gallery/15.webp";
import image_16 from "../../assets/img/2023/gallery/16.webp";
import image_17 from "../../assets/img/2023/gallery/17.webp";
import image_18 from "../../assets/img/2023/gallery/18.webp";
import image_19 from "../../assets/img/2023/gallery/19.webp";
import image_20 from "../../assets/img/2023/gallery/20.webp";
import image_21 from "../../assets/img/2023/gallery/21.webp";
import image_22 from "../../assets/img/2023/gallery/22.webp";
import image_23 from "../../assets/img/2023/gallery/23.webp";
import image_24 from "../../assets/img/2023/gallery/24.webp";
import image_25 from "../../assets/img/2023/gallery/25.webp";
import image_26 from "../../assets/img/2023/gallery/26.webp";
import image_27 from "../../assets/img/2023/gallery/27.webp";
import image_28 from "../../assets/img/2023/gallery/28.webp";
import image_29 from "../../assets/img/2023/gallery/29.webp";
import image_30 from "../../assets/img/2023/gallery/30.webp";
import image_31 from "../../assets/img/2023/gallery/31.webp";
import image_32 from "../../assets/img/2023/gallery/32.webp";
import image_33 from "../../assets/img/2023/gallery/33.webp";
import image_34 from "../../assets/img/2023/gallery/34.webp";
import image_35 from "../../assets/img/2023/gallery/35.webp";
import image_36 from "../../assets/img/2023/gallery/36.webp";
import image_37 from "../../assets/img/2023/gallery/37.webp";
import image_38 from "../../assets/img/2023/gallery/38.webp";
import image_39 from "../../assets/img/2023/gallery/39.webp";
import image_40 from "../../assets/img/2023/gallery/40.webp";
import image_41 from "../../assets/img/2023/gallery/41.webp";
import image_42 from "../../assets/img/2023/gallery/42.webp";
import image_43 from "../../assets/img/2023/gallery/43.webp";
import image_44 from "../../assets/img/2023/gallery/44.webp";
import image_45 from "../../assets/img/2023/gallery/45.webp";
import image_46 from "../../assets/img/2023/gallery/46.webp";
import image_47 from "../../assets/img/2023/gallery/47.webp";
import image_48 from "../../assets/img/2023/gallery/48.webp";
import image_49 from "../../assets/img/2023/gallery/49.webp";
import image_50 from "../../assets/img/2023/gallery/50.webp";
import image_51 from "../../assets/img/2023/gallery/51.webp";
import image_52 from "../../assets/img/2023/gallery/52.webp";
import image_53 from "../../assets/img/2023/gallery/53.webp";
import image_54 from "../../assets/img/2023/gallery/54.webp";
import image_55 from "../../assets/img/2023/gallery/55.webp";
import image_56 from "../../assets/img/2023/gallery/56.webp";
import image_57 from "../../assets/img/2023/gallery/57.webp";
import image_58 from "../../assets/img/2023/gallery/58.webp";
import image_59 from "../../assets/img/2023/gallery/59.webp";
import image_60 from "../../assets/img/2023/gallery/60.webp";
import image_61 from "../../assets/img/2023/gallery/61.webp";
import image_62 from "../../assets/img/2023/gallery/62.webp";
import image_63 from "../../assets/img/2023/gallery/63.webp";
import image_64 from "../../assets/img/2023/gallery/64.webp";
import image_65 from "../../assets/img/2023/gallery/65.webp";
import image_66 from "../../assets/img/2023/gallery/66.webp";
import image_67 from "../../assets/img/2023/gallery/67.webp";
import image_68 from "../../assets/img/2023/gallery/68.webp";
import image_69 from "../../assets/img/2023/gallery/69.webp";
import image_70 from "../../assets/img/2023/gallery/70.webp";
import image_71 from "../../assets/img/2023/gallery/71.webp";
import image_72 from "../../assets/img/2023/gallery/72.webp";
import image_73 from "../../assets/img/2023/gallery/73.webp";
import image_74 from "../../assets/img/2023/gallery/74.webp";
import image_75 from "../../assets/img/2023/gallery/75.webp";
import image_76 from "../../assets/img/2023/gallery/76.webp";
import image_77 from "../../assets/img/2023/gallery/77.webp";
import image_78 from "../../assets/img/2023/gallery/78.webp";
import image_79 from "../../assets/img/2023/gallery/79.webp";
import image_80 from "../../assets/img/2023/gallery/80.webp";
import image_81 from "../../assets/img/2023/gallery/81.webp";
import image_82 from "../../assets/img/2023/gallery/82.webp";
import image_83 from "../../assets/img/2023/gallery/83.webp";
import image_84 from "../../assets/img/2023/gallery/84.webp";
import image_85 from "../../assets/img/2023/gallery/85.webp";
import image_86 from "../../assets/img/2023/gallery/86.webp";
import image_87 from "../../assets/img/2023/gallery/87.webp";
import image_88 from "../../assets/img/2023/gallery/88.webp";

const images = [
  { id: 1, src: image_1, alt: "image1" },
  { id: 2, src: image_2, alt: "image2" },
  { id: 3, src: image_3, alt: "image3" },
  { id: 4, src: image_4, alt: "image4" },
  { id: 5, src: image_5, alt: "image5" },
  { id: 6, src: image_6, alt: "image6" },
  { id: 7, src: image_7, alt: "image7" },
  { id: 8, src: image_8, alt: "image8" },
  { id: 9, src: image_9, alt: "image9" },
  { id: 10, src: image_10, alt: "image10" },
  { id: 12, src: image_12, alt: "image12" },
  { id: 13, src: image_13, alt: "image13" },
  { id: 14, src: image_14, alt: "image14" },
  { id: 15, src: image_15, alt: "image15" },
  { id: 16, src: image_16, alt: "image16" },
  { id: 17, src: image_17, alt: "image17" },
  { id: 18, src: image_18, alt: "image18" },
  { id: 19, src: image_19, alt: "image19" },
  { id: 20, src: image_20, alt: "image20" },
  { id: 21, src: image_21, alt: "image21" },
  { id: 22, src: image_22, alt: "image22" },
  { id: 23, src: image_23, alt: "image23" },
  { id: 24, src: image_24, alt: "image24" },
  { id: 25, src: image_25, alt: "image25" },
  { id: 26, src: image_26, alt: "image26" },
  { id: 27, src: image_27, alt: "image27" },
  { id: 28, src: image_28, alt: "image28" },
  { id: 29, src: image_29, alt: "image29" },
  { id: 30, src: image_30, alt: "image30" },
  { id: 31, src: image_31, alt: "image31" },
  { id: 32, src: image_32, alt: "image32" },
  { id: 33, src: image_33, alt: "image33" },
  { id: 34, src: image_34, alt: "image34" },
  { id: 35, src: image_35, alt: "image35" },
  { id: 36, src: image_36, alt: "image36" },
  { id: 37, src: image_37, alt: "image37" },
  { id: 38, src: image_38, alt: "image38" },
  { id: 39, src: image_39, alt: "image39" },
  { id: 40, src: image_40, alt: "image40" },
  { id: 41, src: image_41, alt: "image41" },
  { id: 42, src: image_42, alt: "image42" },
  { id: 43, src: image_43, alt: "image43" },
  { id: 44, src: image_44, alt: "image44" },
  { id: 45, src: image_45, alt: "image45" },
  { id: 46, src: image_46, alt: "image46" },
  { id: 47, src: image_47, alt: "image47" },
  { id: 48, src: image_48, alt: "image48" },
  { id: 49, src: image_49, alt: "image49" },
  { id: 50, src: image_50, alt: "image50" },
  { id: 51, src: image_51, alt: "image51" },
  { id: 52, src: image_52, alt: "image52" },
  { id: 53, src: image_53, alt: "image53" },
  { id: 54, src: image_54, alt: "image54" },
  { id: 55, src: image_55, alt: "image55" },
  { id: 56, src: image_56, alt: "image56" },
  { id: 57, src: image_57, alt: "image57" },
  { id: 58, src: image_58, alt: "image58" },
  { id: 59, src: image_59, alt: "image59" },
  { id: 60, src: image_60, alt: "image60" },
  { id: 61, src: image_61, alt: "image61" },
  { id: 62, src: image_62, alt: "image62" },
  { id: 63, src: image_63, alt: "image63" },
  { id: 64, src: image_64, alt: "image64" },
  { id: 65, src: image_65, alt: "image65" },
  { id: 66, src: image_66, alt: "image66" },
  { id: 67, src: image_67, alt: "image67" },
  { id: 68, src: image_68, alt: "image68" },
  { id: 69, src: image_69, alt: "image69" },
  { id: 70, src: image_70, alt: "image70" },
  { id: 71, src: image_71, alt: "image71" },
  { id: 72, src: image_72, alt: "image72" },
  { id: 73, src: image_73, alt: "image73" },
  { id: 74, src: image_74, alt: "image74" },
  { id: 75, src: image_75, alt: "image75" },
  { id: 76, src: image_76, alt: "image76" },
  { id: 77, src: image_77, alt: "image77" },
  { id: 78, src: image_78, alt: "image78" },
  { id: 79, src: image_79, alt: "image79" },
  { id: 80, src: image_80, alt: "image80" },
  { id: 81, src: image_81, alt: "image81" },
  { id: 82, src: image_82, alt: "image82" },
  { id: 83, src: image_83, alt: "image83" },
  { id: 84, src: image_84, alt: "image84" },
  { id: 85, src: image_85, alt: "image85" },
  { id: 86, src: image_86, alt: "image86" },
  { id: 87, src: image_87, alt: "image87" },
  { id: 88, src: image_88, alt: "image88" },
  { id: 11, src: image_11, alt: "image11" },
];

const Gallery = () => {
  return (
    <section className="gallery_section extra_spacing">
      <div className="section_title">
        <h2>Visual Highlights</h2>
        {/* <Link to={"/gallery"} className="view_all_link">
          View All
        </Link> */}
      </div>
      <div className="container-fluid">
        <div className="gallery_images">
          {images.map((image) => {
            return (
              <div key={image.id} className="gallery_image_container">
                <img
                  className="gallery_image"
                  src={image.src}
                  alt={image.alt}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Gallery;
