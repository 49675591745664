import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useRef } from "react";

import owner_1 from "../../../../assets/img/2023/testimonials2023/1.webp";
import brand_1 from "../../../../assets/img/2023/testimonials2023/Brand_1.webp";
import category_1 from "../../../../assets/img/2023/testimonials2023/category_1.webp";

import pansari_logo from "../../../../assets/img/2023/testimonials2023/pansari-logo.webp";
import aroma_logo from "../../../../assets/img/2023/testimonials2023/aroma-logo.webp";
import tbh_logo from "../../../../assets/img/2023/testimonials2023/tbh-logo.webp";
import peesafe_logo from "../../../../assets/img/2023/testimonials2023/pee-safe-logo.webp";
import bsc_logo from "../../../../assets/img/2023/testimonials2023/bombay-sahving-company-logo.webp";

const images = [
  {
    id: 1,
    // image: owner_1,
    name: "Pansari Group",
    designation: "",
    // brand_image: brand_1,
    category_image: pansari_logo,
    description:
      "I would like to thank Channelier group for giving us New Product Launch of the Year Award. It is an important recognition to our product, as well as our brand, and will definitely motivate us to make more such healthy products in the future.",
  },
  {
    id: 2,
    // image: owner_1,
    name: "Aroma Galaxy",
    designation: "",
    // brand_image: brand_1,
    category_image: aroma_logo,
    description:
      "Thank you Channelier for recognising Aroma Galaxy as the Brand of the Year. This award means a lot to us as it has not only uplifted our brand image but also the motivation levels of the entire team. Congratulations to Channelier team for making this event a grand success.",
  },
  {
    id: 3,
    // image: owner_1,
    name: "To Be Honest",
    designation: "",
    // brand_image: brand_1,
    category_image: tbh_logo,
    description:
      "We have been using Channelier for a year and the experience has been great. I have used many SaaS products in the market but Channelier is AWESOME!",
  },
  {
    id: 4,
    // image: owner_1,
    name: "Pee Safe",
    designation: "",
    // brand_image: brand_1,
    category_image: peesafe_logo,
    description:
      "The event was very interesting and engaging, kudos to the entire team. More events like this should happen. Already looking forward for the next event.",
  },
  {
    id: 5,
    // image: owner_1,
    name: "Bombay Shaving Company",
    designation: "",
    // brand_image: brand_1,
    category_image: bsc_logo,
    description:
      "Thank you for honouring us with the Product Launch of the Year award! We've been associated with Channelier for a year and they've been lovely hosts of this beautiful and successful event. We're looking forward to a greater association in the future.",
  },
];

const Testimonial2023 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const swiperRef = useRef(null);
  const handleNext = () => {
    swiperRef.current.swiper.slideNext();
    setCurrentIndex(swiperRef.current.swiper.activeIndex % images.length);
  };
  const handlePrev = () => {
    swiperRef.current.swiper.slidePrev();
    setCurrentIndex(swiperRef.current.swiper.activeIndex % images.length);
  };
  const handleSlideBtnClick = (index) => {
    swiperRef.current.swiper.slideTo(index);
    setCurrentIndex(swiperRef.current.swiper.activeIndex % images.length);
  };
  console.log(currentIndex);
  return (
    <section className="testimonial_section" id="testimonial_home">
      <div className="section_title testimonial_header">
        <h2>Testimonials</h2>
        <div className="testimonial_swiper_buttons">
          <button onClick={handlePrev} className="testimonial_buttons">
            <FaArrowLeft color="#FFFFFF" />
          </button>
          <button onClick={handleNext} className="testimonial_buttons">
            <FaArrowRight color="#FFFFFF" />
          </button>
        </div>
      </div>
      <div className="container-fluid">
        <Swiper
          ref={swiperRef}
          freeMode={true}
          //   a11y={true}
          autoplay={{
            delay: 0.5,
            disableOnInteraction: true,
          }}
          //   speed={11000}
          slidesPerView={1}
          loop={true}
          spaceBetween={30}
          className="Testimonial_slide"
          breakpoints={{
            425: {
              slidesPerView: 1,
              spaceBetween: 70,
            },
          }}
        >
          {images &&
            images.map((image) => {
              return (
                <SwiperSlide key={image.id} className="Testimonial_slide">
                  {/* <div className="testimonials_left">
                    <div className="testimonials_back_ground"></div>
                    <img
                      className="testimonial_user_image"
                      src={image.image}
                      alt=""
                    />
                    <img
                      src={image.brand_image}
                      alt=""
                      className="testimonial_brand_image"
                    />
                  </div> */}
                  <div className="testimonials_right">
                    <div className="award_image">
                      <img src={image.category_image} />
                    </div>
                    <div className="testimonials_description">
                      <p>{image.description}</p>
                    </div>
                    <div className="testimonial_author">
                      <h5>
                        {` ${image.name}`}
                        {/* |{image.designation} */}
                      </h5>
                    </div>
                    <div className="bottom_slide_navigation">
                      <div className="dot_pointers">
                        {images.map((image, index) => {
                          return (
                            <button
                              onClick={() => handleSlideBtnClick(index)}
                              key={index}
                              className={`bottom_nav_btn ${
                                currentIndex === index
                                  ? "active_botton_dots"
                                  : ""
                              }`}
                            ></button>
                          );
                        })}
                      </div>
                      {/* <button
                        onClick={handleNext}
                        className="next_slide_button"
                      >
                        <FaArrowRight />
                      </button> */}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonial2023;
