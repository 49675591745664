import React from "react";
// import "./policy.css";
import InfoCard from "../../../components/InfoCard/InfoCard";

const data = [
  {
    title: "The Pride -",
    description: [
      {
        data: "The Channelier FMCG Awards offer an outstanding chance for companies to distinguish themselves from their competitors. These awards are highly respected for acknowledging excellence and success in the FMCG sector.",
        fontSize: "16px",
        type: "text",
      },
    ],
  },
  {
    title: "The Promotion -",
    description: [
      {
        data: "Winning an award can be immensely beneficial for a brand's PR potential and can result in being featured in leading FMCG and retail magazines. It's an opportunity to proudly advertise the fact that the brand's work has been recognized as award-winning.",
        fontSize: "16px",
        type: "text",
      },
    ],
  },
  {
    title: "The Premise -",
    description: [
      {
        data: "Channelier FMCG is known for its strong focus and up-to-date attitude toward the FMCG industry, and the awards reflect this. With six categories, the awards are always in tune with the industry, mirroring the way it operates in reality.",
        fontSize: "16px",
        type: "text",
      },
    ],
  },
  {
    title: "The Positivity -",
    description: [
      {
        data: "Despite facing unprecedented challenges in recent years, the FMCG industry has demonstrated remarkable ingenuity and resilience. These awards provide a platform to celebrate the incredible work that has been done and to recognize the outstanding achievements of the industry in the past year.",
        fontSize: "16px",
        type: "text",
      },
    ],
  },
  {
    title: "The Prestige -",
    description: [
      {
        data: "Winning an award means getting the brand's work in front of an esteemed panel of industry experts, including influential business leaders, investors, and startup entrepreneurs who have previously been involved.",
        fontSize: "16px",
        type: "text",
      },
    ],
  },
];

const Why = () => {
  return (
    <section className="why_participate">
      <div className="section_title">
        <h2>WHY (REASONS TO PARTICIPATE)</h2>
      </div>
      <div className="container-fluid ">
        <div className="why_participate_container">
          {data.map((item, index) => {
            return <InfoCard data={item} key={index} />;
          })}
        </div>
        {/* <div className="why_participate_container">
          {data.slice(3, 5).map((item, index) => {
            return <InfoCard data={item} key={index} />;
          })}
        </div> */}
      </div>
    </section>
  );
};

export default Why;
