import { Row, Col } from "react-bootstrap";
import { FloatingLabel, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import rotateTxt from "../../../../assets/img/rotateTxt.png";
import { BsArrowRight } from "react-icons/bs";
import { useState } from "react";
import { useDispatch } from "react-redux";
import user from "../../../../store/action/user";
import { ToastContainer, toast } from "react-toastify";

// import facebook from "../../../../assets/img/facebook.png";
// import instagram from "../../../../assets/img/instagram.png";
// import linkedin from "../../../../assets/img/linkedin.png";
// import channelier from "../../../../assets/img/logo-icon.png";

function Footer() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [message, setMessage] = useState();
  const dispatch = useDispatch();

  const submitForm = (e) => {
    e.preventDefault();
    if (name && email && mobile && message) {
      const body = [
        { name: "userName", value: name },
        { name: "userEmail", value: email },
        { name: "userContact", value: mobile },
        { name: "query", value: message },
      ];
      dispatch(user.saveUserQuery(body));
      toast.success(
        "Hello! Thanks for sharing your details. Our team will get in touch with you shortly. !",
        {
          autoClose: 1000,
        }
      );
    } else {
      toast.warn("All fields are compulsory !", {
        autoClose: 1000,
      });
    }
  };
  return (
    <section className="footer_section bg-brown" id="contact">
      <div className="container-fluid">
        <ToastContainer />
        <Row>
          <Col lg="6" md="12">
            <div className="footer_form_div">
              <img src={rotateTxt} alt="" className="rotataImg" />
              <form>
                <div className="footer_form">
                  <div className="form_inner">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter your name"
                      className="mb-4"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter your phone no."
                      className="mb-4"
                    >
                      <Form.Control
                        type="text"
                        maxLength={10}
                        minLength={10}
                        placeholder="Enter your phone no."
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter your email"
                      className="mb-4"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Message"
                      className="mb-4"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </FloatingLabel>
                    <button
                      type="submit"
                      className="submitDiv"
                      onClick={(e) => submitForm(e)}
                    >
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
          <Col lg="6" md="12">
            <div className="footer_details">
              <div className="footer_text">
                <h3 className="mb-4">Get In Touch</h3>
                <h4>Contact Us</h4>
                <p>
                PF43, Crystal Plaza, <br/> Hiranandani, Sector-7, <br/> Kharghar, Navi Mumbai ,410210

                
                </p>

                {/* <p className="mb-0">
                  <a href="telto:+91 8882957186;">+91 8882957186</a>
                </p>

                <p className="mb-3">
                  <a href="telto:+91 7973794384;">+91 7973794384</a>
                </p> */}
              </div>
              {/* <div className="footer_links">
                <ul>
                  <li>
                    <a href="#about"> About Us </a>
                  </li>
                  <li>
                    <a
                      href="https://channelier.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Channelier
                    </a>
                  </li>
                  <li>
                    <a href="/why-participate"> Why Participate</a>
                  </li>
                  <li>
                    <a href="/how-to-participate"> How to Participate</a>
                  </li>
                  <li>
                    <a href="/evaluation-parameter"> Evaluation Parameter</a>
                  </li>

                  <li>
                    <a href="#benefit"> Benefits</a>
                  </li>
                  <li>
                    <a href="/winner-category"> Winners</a>
                  </li>
                  <li>
                    <a href="#category"> Categories</a>
                  </li>
                </ul>
              </div> */}
            </div>

            <div className="footer_bottom">
              <div className="social_connect">
                <h4 className="mb-4">Follow Us On</h4>
                <div className="social_links">
                  <ul>
                    <li>
                      {/* <img src={facebook} alt="icon" /> */}
                      <a
                        href="https://www.facebook.com/channelier/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      {/* <img src={instagram} alt="icon" /> */}
                      <a
                        href="https://www.instagram.com/channelier_/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      {/* <img src={linkedin} alt="icon" /> */}
                      <a
                        href="https://www.linkedin.com/company/channelier/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Linkedin
                      </a>
                    </li>
                    <li>
                      {/* <img src={channelier} alt="icon" /> */}
                      <a
                        href="https://channelier.com/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Channelier
                      </a>
                    </li>
                  </ul>
                </div>
                <p>
                  © Arachnomesh Technologies, 2023
                  <Link to="/policy">Privacy Policy and T&C </Link>
                  <Link to="/faq"> FAQs</Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Footer;
