// Importing the requirements
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/header/Header";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BgAnimate from "./Home/components/ParticleComponent/Particle";
import Footer from "./Home/components/FooterSection/Footer";


// Blank Layout Function
const BlankLayout = () => {
  
const location = useLocation();
const url = location.pathname;

return (

  <div className={url.includes("winners") ? "winnerpage homepage" : "homepage"}>
    <BgAnimate/>
    <ToastContainer />
    <Header />
    <Outlet />
    <Footer/>
  </div>


);
}

export default BlankLayout;
