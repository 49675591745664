// Importing Requirements
import React, { useEffect, useState } from 'react';

import { FloatingLabel, Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import "react-toastify/dist/ReactToastify.css"
import user from '../../store/action/user';
import placeholder from '../../assets/img/placeholder.webp'

import SuccessVote from './successVote';


// Function to Render the Login Form
function VoteLogin({ nominationData, showHandler }) {
  const [mobile, setMobile] = useState();
  const [otp, setOtp] = useState();
  const [validated, setValidated] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false)
  const [showPopup, setShowPopup] = useState(false);
  const [isAlreadyExist, setisAlreadyExist] = useState(false);
  const [alreadyVotedData,setAlreadyVotedData]=useState([])

  const voteDetail = useSelector((state) => state.user.voteData)
  const otpVerification = useSelector((state) => state.user.otpVerified)
  console.log(nominationData);
  const dispatch = useDispatch()

  useEffect(() => {
    if (voteDetail.isExist) {
      // setisAlreadyExist(true)
      setAlreadyVotedData(nominationData.map(data=>{
        if (voteDetail.data.includes(data._id)) {
          return data
        }
         
      }))
    }
    if (voteDetail.isAlreadyVoted) {
      toast.warn(`You have alredy voted for ${voteDetail.data} nominations !`, {
        autoClose: 3000
      })
      showHandler(false)
    }
    if (voteDetail.isUpdated || voteDetail.isSaved) {
      setShowPopup(true)
    }
  }, [voteDetail])

  useEffect(() => {
    if (otpVerification && otpVerification === -1) {
      toast.warn("Entered OTP is incorrect !", {
        autoClose: 1000
      })

    } else {
      if(otpVerification && otpVerification===1){
      const nominationId = nominationData.map((data) => data._id)
      const voteUser = {
        mobile: mobile, nominationId: nominationId, verified: true
      }
      dispatch(user.saveUserVote(voteUser))
    }
  }



  return()=>{
    dispatch(user.resetOtp())
    dispatch(user.resetVote())
    setisAlreadyExist(false)
    setAlreadyVotedData(false)
    
  }
  }, [otpVerification])

  // Function that triggers on Verify button
  const handleOtpSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) event.stopPropagation();
    else verifyOtp();
    setOtpValidated(true);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) event.stopPropagation();
    else sendOtpToUser();
    setValidated(true);

  };

  // Function to verify otp
  const verifyOtp = () => {
    const loginDetailObject = {
      number: mobile,
      OTP: otp,
    }
    dispatch(user.verifyOtp(loginDetailObject))

  }
  // Function to confirm whether the otp is sent or not
  const showOtpSent = () => {
    toast.success("OTP Sent Successfully !", {
      autoClose: 1000
    })
  }

  const sendOtpToUser = () => {
    if (mobile !== "" && mobile.length === 10) {
      setIsOtpSent(true);
      const mobileDetailObject = {
        number: mobile,
        source:"vote"
      }
      dispatch(user.sendOtp(mobileDetailObject, showOtpSent))
    }
    else toast.warn("Please Enter a Valid Mobile Number !", {
      autoClose: 1000
    })
  }

  const getImageSource = (imageLink) => {
    if (imageLink) {
      const imageLinkChunks = imageLink.split('/');
      return `https://awards.channelier.com/uploads/${imageLinkChunks[imageLinkChunks.length - 1]}`
    }
  }

  return (
    <div>
      <ToastContainer />
      <div className='voterLogin'>
        {/* <h2>Login</h2> */}
        {/* OTP UI Before click on submit Button to send otp to the user */}
        {!isOtpSent &&
          <>
            {/* <h6>Welcome back</h6> */}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <FloatingLabel controlId="floatingInput" label="Mobile number" className="mb-1">
                <Form.Control required type="phone" placeholder="Mobile Number" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                <Form.Control.Feedback type="invalid">Mobile Number is Required.</Form.Control.Feedback>
              </FloatingLabel>
              <p className='warningTxt'>*Do not add 0 or +91 before your Mobile Number</p>
              <div className='text-center mt-5'>
                <button type='submit' className='submitVote hvr-shutter-out-vertical'>
                  <span className='triangleShape'></span>
                  Submit
                </button>
              </div>
            </Form>
          </>

        }
        {/* OTP UI after click on submit button to send otp to the user */}
        {isOtpSent &&
          <>
            {/*   
  <h6>
    <span className = 'back' onClick={() => setIsOtpSent(false)} >
      <FiArrowLeft />
    </span> 
    Welcome back
  </h6> */}

            <Form noValidate validated={otpValidated} onSubmit={handleOtpSubmit}>
              <FloatingLabel controlId="floatingInput" label="Mobile number" className="mb-1">
                <Form.Control required type="phone" placeholder="Mobile Number" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                <Form.Control.Feedback type="invalid">Mobile number is Required</Form.Control.Feedback>
              </FloatingLabel>

              <p className='warningTxt'>*Do not add 0 or +91 before your Mobile Number</p>

              <FloatingLabel controlId="floatingInput" label="Enter your OTP" className="mb-2">
                <Form.Control required type="phone" placeholder="Enter your OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
                <Form.Control.Feedback type="invalid">OTP is Required</Form.Control.Feedback>
              </FloatingLabel>

              <p className='text-end'> <Button className='link_btn' variant='Link' onClick={() => sendOtpToUser()}>Resend OTP</Button></p>

              <div className='text-center'>
                <button type='submit' className='submitVote hvr-shutter-out-vertical'>
                  <span className='triangleShape'></span>
                  Verify
                </button>
              </div>

              {/* <Button type = 'submit' className = 'custom_btn brown_btn w-100 mb-2' >Verify</Button> */}

            </Form>
            <SuccessVote showHandler={setShowPopup} show={showPopup} loginHandler={showHandler} />

          </>
        }


        <div className={`popupContainer ${isAlreadyExist ? "show" : ""}`}>
          <div className="popup">
            <h2> Already Voted !</h2>
            <p>You have already voted for the following nomination please remove and select other nomination</p>
            {alreadyVotedData && alreadyVotedData.map((nomination, index) =>
              
              <div className='alreadyNominated'  key={index}>
                {nomination?.userProfile.length && typeof(nomination?.userProfile[0]?.productImage)!=="string" && nomination?.userProfile[0]?.productImage?.image!==""&& nomination?.userProfile[0]?.productImage?.image!=="null" &&  nomination?.userProfile[0]?.productImage?.image!==null?
                                <img src={getImageSource(nomination?.userProfile[0]?.productImage?.image)} alt='' />
                            : <img src={require('../../assets/img/placeholder.webp')} alt='' />}
               
                <span>{nomination?.productName}</span>
              </div>

            )}
            <div className="button">
              <button id="close1" className="custom_btn brown_btn" type="button" onClick={() => {
                setisAlreadyExist(false)
                showHandler(false)
              }} >Close</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  );

}

// Exprting the Login Function
export default VoteLogin;