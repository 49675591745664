import React  from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import tick from "../../assets/img/success_icon.png";

function SuccessVote({show,showHandler,loginHandler}) {

  const handleClose = () => {
    showHandler(false);
    loginHandler(false)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="successVoteModal"
      >
            <Modal.Body>
              <div className="successVote">
                <img src={tick} alt='success' />
                <p>Your Vote has been Successfully Submitted.</p>
                <span>{new Date().toUTCString()}</span>
              </div>
           
            </Modal.Body>
            <Modal.Footer className="p-1">
              <Link to={'/voting-category'}><Button className="custom_btn brown_btn">Ok</Button></Link>
            </Modal.Footer>
          
      </Modal>
    </>
  );
}

export default SuccessVote;
