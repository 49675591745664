import { React } from "react";
// import image2 from "../../assets/img/2023_logo.png";

function LiveEvent() {
  return (
    <section className="">
      <div className="container">
         <div className="live_event">
         <iframe width="100%" height="700" src="https://www.youtube.com/embed/h4KdJeGFkdM?si=XAwDJlQN4bNk1dHd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            {/* <img src={image2} alt="img"/>
             <h1>Join us on 3rd Feb 2024 at 5:00 p.m</h1>
            <p>to witness the live premiere of</p>
            <h2>Channelier FMCG Awards 2023</h2> */}
         </div>
      </div>
    </section>
  );
}

export default LiveEvent;
