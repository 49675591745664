import React, { useEffect, useState } from 'react';

import backArrow from '../../assets/img/arrowBack.png';
import user from '../../store/action/user';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import LoginVotePopup from './loginVotePopup';

function VotePage() {
    const brandOfTheYearId = "5eaa9ed95aebf36094336982";
    const launchOfTheYearId = "5eaa9ea15aebf36094336981";
    const productOfTheYearId = "5eaa9dd55aebf3609433697d";
    const [selectedVoteCOunt, setSelectedVoteCount] = useState(0)
    const nominationdata = useSelector(state => state.user.nominationData)
    const [selectedNomination,setSelectedNomination]=useState([]);
    const startDate = "2021-12-31T18:30:00.000Z";
    const endDate = "2022-12-30T18:30:00.000Z";
    const dispatch = useDispatch()
    const { category } = useParams();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const productId = category === 'brand' ? brandOfTheYearId : category === 'product' ? productOfTheYearId : category === 'launch' ? launchOfTheYearId : null;

    useEffect(() => {
        dispatch(user.getCompanyNomination({ awardCategory: productId, startDate, endDate }))
    }, [])


    const selectNomination = (e, index,data) => {
        const selecteNomination=document.getElementById(index);
        const token=selecteNomination.classList.toggle('activeVote')
        console.log(token);
        if (!token) {
            setSelectedVoteCount((prevCount) => prevCount - 1)
            setSelectedNomination(selectedNomination.filter(item=>item._id!==data._id))
            return
        }
        setSelectedVoteCount((prevCount) => prevCount + 1)
        setSelectedNomination((prevNomination)=>[...prevNomination,data])


    }




    const getImageSource = (imageLink) => {
        if (imageLink) {
          const imageLinkChunks = imageLink.split('/');
          return `https://awards.channelier.com/uploads/${imageLinkChunks[imageLinkChunks.length - 1]}`
        }
      }


   


    return (
        <>
            <section className='bg-brown votePage'>
                <div className='container'>

                    <div className='voteTitle'>
                        <h4>
                            <Link to='/voting-category'> <img src={backArrow} alt='icon' /> </Link>

                            {category === 'brand' ? 'Brand' : category === 'product' ? 'Product' : category === 'launch' ? 'Product launch' : null} of the year
                        </h4>
                        <p className='text-center text-white mb-5'>Selects the products for the voting</p>
                    </div>


                    <div className='products'>
                        {nominationdata?.map((data, index) =>
                            <div className='productImg' key={index} id={index} onClick={(e) => selectNomination(e, index,data)}>
                                <>
                                {data?.userProfile.length && typeof(data?.userProfile[0]?.brandLogo)!=="string" && data?.userProfile[0]?.brandLogo?.image!==""&& data?.userProfile[0]?.brandLogo?.image!=="null" &&  data?.userProfile[0]?.brandLogo?.image!==null?
                                <div className='brandLogoVote'>  <img src={getImageSource(data?.userProfile[0]?.brandLogo?.image)} alt='' /> </div>
                                :<div className='brandLogoVote'><p>{data?.user[0]?.companyName}</p> </div>}
                                
                                {data?.userProfile.length && typeof(data?.userProfile[0]?.productImage)!=="string" && data?.userProfile[0]?.productImage?.image!==""&& data?.userProfile[0]?.productImage?.image!=="null" &&  data?.userProfile[0]?.productImage?.image!==null?
                                <div className='productImgVote'> <img src={getImageSource(data?.userProfile[0]?.productImage?.image)} alt='' /> </div>
                            : <div className='productImgVote'><img src={require('../../assets/img/placeholder.webp')} alt='' /> </div>}


                            <span>{data?.productName}</span>

                            </>
                            </div>
                        )}
                    </div>

                    <div className='voteButton'>
                        {/* <p> YOU HAVE VOTED <span className='text-white'>{selectedVoteCOunt}</span> OF <span className='text-white'>{nominationdata.length}</span> NOMINATIONS </p> */}
                        <p> You have voted <span className='text-white'>{selectedVoteCOunt}</span> Nominations </p>
                        

                        {/* <button type='button' className='submitVote hvr-shutter-out-vertical' onClick={verifyUser}> */}
                        <button type='button' className='submitVote hvr-shutter-out-vertical' onClick={handleShow}>
                            <span className='triangleShape'></span>
                            Submit vote
                        </button>

                    </div>



                </div>
                        

            </section>

           <LoginVotePopup   show={show} showHandler={setShow} nominationData={selectedNomination}/>

        </>

    );


}

export default VotePage