import { React, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import backArrow from "../../../../assets/img/arrowBack.png";
import _brand from "../../../../assets/json/2023/brand.json";
import _product from "../../../../assets/json/2023/product.json";
import _launch from "../../../../assets/json/2023/launch.json";
import _digital from "../../../../assets/json/2023/digital.json";
import _d2cbrand from "../../../../assets/json/2023/d2c_brand.json";
import _d2cproduct from "../../../../assets/json/2023/d2c_product.json";

function WinnersDetails2023() {
  const [searchParam, setSearchParam] = useSearchParams();
  const [winnerData, setWinnerData] = useState();
  const [title, setTitle] = useState("");
  let category = searchParam.get("category");

  useEffect(() => {
    if (category === "brand") {
      _brand.map((brand) => {
        brand[
          "image"
        ] = `https://awards.channelier.com/uploads/winner2023/brand/brand_${brand["ID"]}.png`;
        brand[
          "company_image"
        ] = `https://awards.channelier.com/uploads/winner2023/brand/company_${brand["ID"]}.png`;
      });
      setWinnerData(_brand);
      setTitle("Brand of the Year");
    }
    if (category === "product") {
      _product.map((product) => {
        product[
          "image"
        ] = `https://awards.channelier.com/uploads/winner2023/product/product_${product["ID"]}.png`;
        product[
          "company_image"
        ] = `https://awards.channelier.com/uploads/winner2023/product/company_${product["ID"]}.png`;
      });
      setWinnerData(_product);
      setTitle("Product of the Year");
    }
    if (category === "launch") {
      _launch.map((launch) => {
        launch[
          "image"
        ] = `https://awards.channelier.com/uploads/winner2023/launch/product_${launch["ID"]}.png`;
        launch[
          "company_image"
        ] = `https://awards.channelier.com/uploads/winner2023/launch/company_${launch["ID"]}.png`;
      });
      setWinnerData(_launch);
      setTitle("Product Launch of the Year");
    }

    if (category === "digital") {
      _digital.map((digital) => {
        digital[
          "image"
        ] = `https://awards.channelier.com/uploads/winner2023/digital/brand_${digital["ID"]}.png`;
        digital[
          "company_image"
        ] = `https://awards.channelier.com/uploads/winner2023/digital/company_${digital["ID"]}.png`;
      });
      setWinnerData(_digital);
      setTitle("Digital initiative of the year");
    }

    if (category === "d2cbrand") {
      _d2cbrand.map((d2cbrand) => {
        d2cbrand[
          "image"
        ] = `https://awards.channelier.com/uploads/winner2023/d2cbrand/brand_${d2cbrand["ID"]}.png`;
        d2cbrand[
          "company_image"
        ] = `https://awards.channelier.com/uploads/winner2023/d2cbrand/company_${d2cbrand["ID"]}.png`;
      });
      setWinnerData(_d2cbrand);
      setTitle("D2C Brand of the year");
    }


    if (category === "d2cproduct") {
      _d2cproduct.map((d2cproduct) => {
        d2cproduct[
          "image"
        ] = `https://awards.channelier.com/uploads/winner2023/d2cproduct/product_${d2cproduct["ID"]}.png`;
        d2cproduct[
          "company_image"
        ] = `https://awards.channelier.com/uploads/winner2023/d2cproduct/company_${d2cproduct["ID"]}.png`;
      });
      setWinnerData(_d2cproduct);
      setTitle("D2C Product of the year");
    }
  }, [category]);

  console.log(winnerData);

  return (
    <section className="bg-brown winnerCategory h-100">
      <div className="container">
        <div className="voteTitle">
          <h4 className="mb-5">
            <Link to="/winner-category2023">
              {" "}
              <img src={backArrow} alt="icon" />{" "}
            </Link>
            {title}
          </h4>
          {/* <p className="text-center text-white mb-5">
            you can check winners here
          </p> */}
        </div>
        <div className="products h-auto">
          {winnerData?.map((data, index) => (
            <div key={index} className="productImg">
              <div className="brandLogoVote">
                {" "}
                <img src={data?.company_image} alt="productLogo" />{" "}
              </div>
              <div className="productImgVote">
                {" "}
                <img src={data?.image} alt="product img" />{" "}
              </div>
              <span>
                {category !== "brand"
                  ? data["Product Name"]
                  : data["Company name"]}
              </span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WinnersDetails2023;
