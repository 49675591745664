import React from "react";
import mainImage from "../../../../assets/img/2023/pr_section/main.webp";
const PRComponent = () => {
  return (
    <section className="pr_sec" id="pr_home">
      <div className="section_title">
        <h2>In the News</h2>
      </div>
      <div className="container-fluid">

       <div className="col-lg-12 text-center">
            <div className="pr_background_container"></div>
            <img
              className="pr_main_image"
              src={mainImage}
              alt="Channelier in news"
            />
          </div>
      
        {/* <div className="pr_container">
          <div className="pr_container_left">
            <h6>REPORTS</h6>
            <h3>Get the whole picture. Finally.</h3>
            <p>
              “FMCG Industry's Top Achievers Honored <br /> at Prestigious
              Awards Event - Celebrating <br /> Innovation, Excellence and
              Growth!"
            </p>
          </div>
      
        </div> */}
      </div>
    </section>
  );
};

export default PRComponent;
